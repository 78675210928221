"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const globalSearch_state_1 = require("@/state/globalSearch.state");
exports.default = (0, vue_1.defineComponent)({
    name: 'ClientFilter',
    props: {
        filterGroup: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        title: 'Client',
        selectedItem: null,
    }),
    computed: {
        items() {
            return this.filterGroup.filters;
        },
    },
    watch: {
        selectedItem: {
            handler: function (newVal) {
                console.log('selectedItem', newVal);
                this.$emit('filter', newVal === null || newVal === void 0 ? void 0 : newVal.filterExpression);
            },
        },
    },
    mounted() {
        (0, vue_1.watch)(() => globalSearch_state_1.globalSearchStore.searchInput, newSearchInput => {
            if (!newSearchInput) {
                this.selectedItem = null;
            }
        });
    },
});
