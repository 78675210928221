import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"400px"},model:{value:(_vm.showDialogue),callback:function ($$v) {_vm.showDialogue=$$v},expression:"showDialogue"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Change Mail")]),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column"},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"hide-details":"","label":"Mail","persistent-placeholder":"","placeholder":"-"},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1)]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showDialogue = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":!_vm.mailValid},on:{"click":function($event){$event.stopPropagation();return _vm.changeEmail()}}},[_vm._v("Accept")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }