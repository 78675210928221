"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_demi_1 = require("vue-demi");
exports.default = (0, vue_demi_1.defineComponent)({
    data: () => ({
        showConfirmDialog: false,
    }),
    props: {
        colorConfirm: { type: String, default: 'error' },
        confirmTitle: { type: String, default: 'Sind Sie sicher?' },
        actionText: { type: String, default: 'Löschen' },
        description: { type: String, default: undefined },
        noPadding: { type: Boolean },
        noMinWidth: { type: Boolean },
    },
    watch: {},
    async mounted() { },
    computed: {
        buttonAttrs() {
            return Object.assign({ color: 'error' }, this.$attrs);
        },
    },
    methods: {
        async confirmed() {
            this.$emit('confirmed');
            this.showConfirmDialog = false;
        },
    },
});
