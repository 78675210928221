"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const baseData_1 = require("@/services/baseData");
const data_1 = require("@/services/data");
const base_1 = require("../../../base");
const lodash_1 = require("lodash");
exports.default = (0, vue_1.defineComponent)({
    props: {
        user: {
            type: Object,
            default: null,
        },
        hideClientIdInput: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isValid: false,
        userAdminLevelOptions: baseData_1.userAdminLevelOptions,
        viewOptions: [],
        availableClientIds: [],
        clients: [],
        selectedClient: null,
        views: base_1.views,
        listViews: null,
        additionalClients: [],
        tags2clients: {},
        requiredRule: (v) => (isNaN(v) ? !!v : true) || 'Field is required',
        onlyTextRule: (v) => /^[a-zA-ZäüöÄÜÖß -]*$/.test(v) || 'Only characters - and Blanks allowed (a-z, A-Z, äöüÄÖÜ, -)',
    }),
    watch: {
        isValid() {
            this.$emit('valid', this.isValid);
        },
        selectedClient() {
            // eslint-disable-next-line vue/no-mutating-props
            this.user.cid = this.selectedClient;
            // eslint-disable-next-line vue/no-mutating-props
            this.user.mainclientid = this.selectedClient;
            console.log(this.user);
        },
        additionalClients() {
            // eslint-disable-next-line vue/no-mutating-props
            this.user.clientsAccessRights = (0, lodash_1.map)(this.additionalClients, cid => ({ cid, adminLevel: this.user.adminLevel }));
            console.log(this.user);
        },
    },
    async mounted() {
        this.listViews = (0, lodash_1.keyBy)(base_1.views, v => v.id);
        const profilesWithtags = await data_1.profileApi.getProfilesWithTags();
        const tags = (0, lodash_1.chain)(profilesWithtags)
            .flatMap(p => p.tags)
            .compact()
            .uniq()
            .value();
        for (const tag of tags) {
            this.tags2clients[tag] = (0, lodash_1.chain)(profilesWithtags)
                .filter(p => { var _a; return ((_a = p.tags) === null || _a === void 0 ? void 0 : _a.includes(tag)) || false; })
                .map(p => p.cid)
                .value();
        }
        const availableViews = await data_1.profileApi.getAvailableViews();
        const clients = await (0, data_1.loadClients)();
        this.viewOptions = availableViews.views.sort();
        this.clients = (0, lodash_1.sortBy)(clients, c => c.cid);
    },
    methods: {
        isViewSelected(viewId) {
            if (!this.user || !this.user.views) {
                return false;
            }
            return this.user.views.includes(viewId);
        },
        addTagClients(clients) {
            this.additionalClients = clients;
        },
        filterClients(item, queryText) {
            var _a, _b;
            const rgx = new RegExp(queryText, 'i');
            if ((_a = item.praxisKurzName) === null || _a === void 0 ? void 0 : _a.match(rgx)) {
                return true;
            }
            if ((_b = item.praxisName) === null || _b === void 0 ? void 0 : _b.match(rgx)) {
                return true;
            }
            if (item.cid.match(rgx)) {
                return true;
            }
            return false;
        },
    },
});
