"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("../../../../types");
exports.default = (0, vue_1.defineComponent)({
    name: 'TicketPrioCell',
    data: () => ({
        params: {},
    }),
    computed: {
        text() {
            switch (this.params.value) {
                case types_1.FreshdeskTicketPriority.Low:
                    return 'Niedrig';
                case types_1.FreshdeskTicketPriority.Medium:
                    return 'Mittel';
                case types_1.FreshdeskTicketPriority.High:
                    return 'Hoch';
                case types_1.FreshdeskTicketPriority.Urgent:
                    return 'Dringend';
                default:
                    return '';
            }
        },
        color() {
            switch (this.params.value) {
                case types_1.FreshdeskTicketPriority.Low:
                    return 'grey';
                case types_1.FreshdeskTicketPriority.Medium:
                    return 'warning';
                case types_1.FreshdeskTicketPriority.High:
                    return 'error';
                case types_1.FreshdeskTicketPriority.Urgent:
                    return 'error darken-2';
                default:
                    return '';
            }
        },
    },
});
