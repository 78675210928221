"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerRenderVNodeComponent = void 0;
const vue_demi_1 = require("vue-demi");
/**
 * Component that allows rendering a vitual dom node.
 * Can be used to render slots of a component more dynamically.
 * render-v-node(:node='$slots.default[0]')
 */
function registerRenderVNodeComponent(vueInstance) {
    vueInstance.component('render-v-node', {
        props: {
            node: { type: [Object, Array], required: true },
            tag: { type: String, default: 'div' },
            options: {
                type: Object,
                default: () => { },
            },
        },
        render: function () {
            let nodes = this.node;
            if (!Array.isArray(nodes)) {
                nodes = [nodes];
            }
            return (0, vue_demi_1.h)(this.tag, this.options, nodes);
        },
    });
}
exports.registerRenderVNodeComponent = registerRenderVNodeComponent;
