"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const common_ui_1 = require("../../../../common-ui");
exports.default = (0, vue_1.defineComponent)({
    props: {
        gutschein: { type: Object, default: () => ({}) },
        forceSponsor: { type: Boolean, default: () => false },
    },
    data: () => ({
        rules: common_ui_1.rules,
    }),
    computed: {
        productsCommaString: {
            get() {
                if (this.gutschein.produkte) {
                    return this.gutschein.produkte.join(', ');
                }
                return '';
            },
            set(commaString) {
                // eslint-disable-next-line vue/no-mutating-props
                this.gutschein.produkte = commaString.split(',').map(s => s.trim());
            },
        },
        sponsorSubscriptionLink() {
            var _a;
            return `https://dashboard.stripe.com/subscriptions/${(_a = this.gutschein.meta) === null || _a === void 0 ? void 0 : _a.sponsorsubscription}`;
        },
        customerSubscriptionLink() {
            var _a;
            return `https://dashboard.stripe.com/subscriptions/${(_a = this.gutschein.meta) === null || _a === void 0 ? void 0 : _a.customersubscription}`;
        },
    },
    methods: {},
});
