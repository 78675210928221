"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    name: 'ChangeMailDialogue',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        stripeInfoComplete: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        rules: {
            required: (value) => !!value || 'Required.',
            email: (value) => /.+@.+/.test(value) || 'E-mail must be valid.',
        },
        mail: '',
    }),
    computed: {
        showDialogue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        mailValid() {
            return this.mail && this.rules.email(this.mail) === true;
        },
    },
    async mounted() {
        await (0, vue_1.nextTick)().then(() => {
            var _a, _b;
            if ((_b = (_a = this.stripeInfoComplete) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.email) {
                this.mail = this.stripeInfoComplete.customer.email;
            }
        });
    },
    methods: {
        async changeEmail() {
            if (this.mailValid) {
                await (0, helpers_1.handleApiCall)({
                    apiFunction: () => this.stripeInfoComplete.customer
                        ? data_1.stripeApi.changeClientEmail(this.stripeInfoComplete.customer.id, this.mail)
                        : Promise.resolve(),
                    errorMessage: 'Error changing email',
                    successMessage: 'Email changed',
                });
            }
            this.showDialogue = false;
        },
    },
});
