import Vue from 'vue';
import Router, { Route } from 'vue-router';

import Root from './views/Root.vue';
import Login from './views/Login.vue';
import Dashboard from './views/Dashboard.vue';
import Clients from './views/Clients.vue';
import Tools from './views/Tools.vue';
import Users from './views/Users.vue';
import Import from './views/Import.vue';
import CaLicences from './views/CaLicences.vue';
import R2CsvLicences from './views/R2CsvLicences.vue';
import Onboarding from './views/Onboarding.vue';
import Status from './views/Status.vue';
import Mailer from './views/Mailer.vue';
import Sponsoring from './views/Sponsoring.vue';
import ClientsMap from './views/ClientsMap.vue';

import UsersActionsComponent from './components/UsersActionsComponent.vue';
import ClientsActionsComponent from '@/components/ClientsActionsComponent.vue';
import ImportActionsComponent from '@/components/import/ImportActionsComponent.vue';
import Subscriptions from '@/views/Subscriptions.vue';
import { loginStore } from '@/state/login.state';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      component: Root,
      beforeEnter: requireAuth,
      children: [
        {
          path: '',
          redirect: '/clients',
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            actionComponent: 'rose-dashboard-actions-components',
            showReloadButton: true,
            showSearchBar: true,
          },
        },
        {
          path: '/clients/:selectedKey?',
          name: 'Clients',
          component: Clients,
          meta: {
            actionComponent: ClientsActionsComponent,
            showSearchBar: true,
            showReloadButton: true,
            solutioTeam: true,
          },
        },
        {
          path: '/users/:selectedKey?',
          name: 'Users',
          component: Users,
          meta: {
            actionComponent: UsersActionsComponent,
            showSearchBar: true,
            showReloadButton: true,
            solutioTeam: true,
          },
        },
        {
          path: '/import/:selectedTab?',
          name: 'Import',
          component: Import,
          meta: {
            actionComponent: ImportActionsComponent,
            showSearchBar: true,
            showReloadButton: true,
            solutioTeam: true,
          },
        },
        {
          path: '/subscriptions/:selectedKey?',
          name: 'Subscriptions',
          component: Subscriptions,
          meta: {
            // actionComponent: UsersActionsComponent,
            showSearchBar: true,
            showReloadButton: true,
            solutioTeam: false,
          },
        },
        {
          path: '/onboarding/:selectedKey?',
          name: 'Onboarding',
          component: Onboarding,
          meta: {
            showReloadButton: true,
            showSearchBar: true,
          },
        },
        {
          path: '/sponsoring/:selectedSponsor?/:selectedGutschein?',
          name: 'Sponsoring',
          component: Sponsoring,
        },
        {
          path: '/map',
          name: 'Map',
          component: ClientsMap,
          meta: {
            // actionComponent: ImportActionsComponent,
            showSearchBar: true,
            showReloadButton: true,
            solutioTeam: true,
          },
        },
        {
          path: '/mailer',
          name: 'Mailer',
          component: Mailer,
        },
        {
          path: '/tools/:view1?/:view2?',
          name: 'Tools',
          component: Tools,
          meta: {
            solutioTeam: false,
          },
        },
        {
          path: '/status/:selectedKey?',
          name: 'status',
          component: Status,
          meta: {
            actionComponent: 'rose-dashboard-actions-components',
            showReloadButton: true,
          },
        },
        {
          path: '/calicence/:selectedLicence?',
          name: 'charly-Lizenz',
          component: CaLicences,
          meta: {
            showReloadButton: true,
            solutioTeam: true,
          },
        },
        {
          path: '/r2csvlicence/:selectedLicence?',
          name: 'r2csv-Lizenz',
          component: R2CsvLicences,
          meta: {
            showReloadButton: true,
            solutioTeam: false,
          },
        },
        {
          path: '*',
          redirect: '/',
        },
      ],
    },
  ],
});

// @ts-ignore
window.router = router;
export default router;

router.beforeEach(requireAuth);

function requireAuth(to: Route, from: Route, next: any) {
  if (to.name === 'login') {
    return next();
  }
  if (!loginStore?.isLoggedIn) {
    return next('login');
  }
  if (loginStore.isSolutioTeam && !to?.meta?.solutioTeam) {
    console.warn(`solutio-user but view is not solutio`);
    return next('clients');
  }

  return next();
}

export function setRouteParam(urlKeyName: string, urlKeyValue: string | undefined) {
  // check if url needs update
  let urlCurrentKey = router.currentRoute.params[urlKeyName];

  if (String(urlCurrentKey) !== String(urlKeyValue)) {
    let params = router.currentRoute.params;
    if (urlKeyValue) {
      params[urlKeyName] = urlKeyValue;
    } else {
      delete params[urlKeyName];
    }

    router.push({
      name: router.currentRoute.name as string,
      params,
    });
  }
}
