"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const common_ui_1 = require("@rose/common-ui");
function currency(value, showDecimal = true) {
    value = value.value;
    const nf = new Intl.NumberFormat();
    return showDecimal ? `${nf.format(value)} €` : `${nf.format(Number(value.toFixed()))} €`;
}
exports.default = (0, vue_1.defineComponent)({
    components: {},
    props: {
        invoices: Array,
    },
    data: () => ({
        rewriteInvoiceDialog: false,
        invoicesHeaders: [
            { headerName: 'Nummer', width: 140, field: 'number' },
            { headerName: 'Datum', width: 100, field: 'created', valueFormatter: common_ui_1.dateFormatter },
            { headerName: 'Betrag', width: 100, field: 'amount', valueFormatter: currency },
            {
                headerName: 'Neuschreiben',
                width: 120,
                field: 'id',
                sortable: false,
                cellRenderer: 'RewriteInvoiceCellComponent',
            },
            {
                headerName: 'Download',
                width: 100,
                field: 'downloadLink',
                sortable: false,
                cellRenderer: 'LinkCellComponent',
                cellRendererParams: {
                    linkText: 'Download',
                    linkIcon: 'fa-download',
                },
            },
            {
                headerName: 'Mail',
                width: 140,
                field: 'id',
                sortable: false,
                cellRenderer: 'ReMailInvoiceCellComponent',
            },
        ],
    }),
    methods: {
        onCellDoubleClicked(event) {
            var _a, _b;
            console.log('open invoice ID', (_a = event.data) === null || _a === void 0 ? void 0 : _a.id);
            window.open(`https://dashboard.stripe.com/invoices/${(_b = event.data) === null || _b === void 0 ? void 0 : _b.id}`, '_blank');
        },
    },
});
