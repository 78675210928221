"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        date: '',
        dateFormatted: '',
        menu1: false,
    }),
    watch: {
        date(val) {
            this.dateFormatted = this.formatDateTime(this.date);
        },
    },
    methods: {
        formatDateTime(date) {
            if (!date) {
                return null;
            }
            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        parseDate(date) {
            if (!date) {
                return null;
            }
            const [day, month, year] = date.split('.');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
    },
});
