import { render, staticRenderFns } from "./CharlyFiles.vue?vue&type=template&id=a39a4934&scoped=true&lang=pug&"
import script from "./CharlyFiles.vue?vue&type=script&lang=ts&"
export * from "./CharlyFiles.vue?vue&type=script&lang=ts&"
import style0 from "./CharlyFiles.vue?vue&type=style&index=0&id=a39a4934&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39a4934",
  null
  
)

export default component.exports