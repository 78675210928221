"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const common_ui_1 = require("@rose/common-ui");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        loading: false,
        failedUploads: [],
        r4cHostMapping: {},
    }),
    computed: {},
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                this.r4cHostMapping = await data_1.profileApi.getR4cHostMapping();
                let res = await data_1.metricsApi.admin.failedCharlyUploads();
                console.log(res);
                this.failedUploads = res;
            }
            catch (error) {
                console.error(error);
            }
            finally {
                this.loading = false;
            }
        },
        async retry(failedTask) {
            try {
                let cid = failedTask.cid;
                let r4chost = this.r4cHostMapping[cid];
                await data_1.r4cServerApi.admin.retryuploadtask(r4chost, failedTask.id);
            }
            catch (error) {
                alert('retry failed ' + error);
                console.error(error);
            }
            void this.init();
        },
        getColumns(res) {
            let pivot = res[0];
            if (!pivot)
                return [];
            return Object.keys(pivot);
        },
        toStringWithTrucate(obj) {
            if (typeof obj === 'string')
                return this.truncate(obj);
            let s = JSON.stringify(obj);
            return this.truncate(s);
        },
        truncate(str, n = 100) {
            return str.length > n ? str.substr(0, n - 1) + '...' : str;
        },
        log(item) {
            (0, common_ui_1.logVueReactiveObject)('log', item);
        },
    },
});
