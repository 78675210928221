"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        params: {},
        icon: null,
        color: null,
    }),
    mounted() {
        this.icon = this.params.context.icon;
        this.color = this.params.context.color;
    },
    methods: {
        deleteItem() {
            this.params.context.clicked({
                row: this.params.data,
                column: this.params.value,
            });
        },
    },
});
