import { render, staticRenderFns } from "./CopyTextComponent.vue?vue&type=template&id=88876784&scoped=true&lang=pug&"
import script from "./CopyTextComponent.vue?vue&type=script&lang=ts&"
export * from "./CopyTextComponent.vue?vue&type=script&lang=ts&"
import style0 from "./CopyTextComponent.vue?vue&type=style&index=0&id=88876784&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88876784",
  null
  
)

export default component.exports