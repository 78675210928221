"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
const lodash_1 = require("lodash");
const vue_1 = require("vue");
const PATH_INFO_CACHE = new Map();
async function loadCharlyPath({ r4chost, cid, path }) {
    let cacheKey = `${r4chost}/${cid}/${path}`;
    if (PATH_INFO_CACHE.has(cacheKey)) {
        let fileInfo = PATH_INFO_CACHE.get(cacheKey);
        return { success: true, fileInfo };
    }
    let res = await data_1.r4cServerApi.admin.proxyCharlyRequest({
        r4chost,
        cid,
        request: {
            url: 'files-service/filing' + path,
            method: 'GET',
        },
    });
    if (res.success) {
        let fileInfo = res.response.data;
        PATH_INFO_CACHE.set(cacheKey, fileInfo);
        return { success: true, fileInfo };
    }
    else {
        return { success: false, error: res.error };
    }
}
let PINNED_PATHS = ['Hintergrund', 'SolutioTemplates', 'SolutioText', '7001'];
exports.default = (0, vue_1.defineComponent)({
    name: 'AsyncFileTree',
    inject: ['openFile', 'downloadFile', 'showError', 'previewFile'],
    props: {
        r4chost: {
            type: String,
            required: true,
        },
        cid: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        expand: {
            type: Boolean,
            default: false,
        },
        preventChildLoad: {
            type: Boolean,
            default: false,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        expanded: false,
        children: [],
        loading: false,
        fileInfo: undefined,
        itemError: null,
        previewFile: null,
        type: '',
    }),
    computed: {
        pathBasename() {
            return this.path.split('/').pop() || '/';
        },
        preventChildLoadForChildren() {
            var _a;
            return ((_a = this.children) === null || _a === void 0 ? void 0 : _a.length) > 300;
        },
        childrenSorted() {
            // move PINNED_PATHS to the top
            let pinned = (0, lodash_1.intersection)(this.children, PINNED_PATHS);
            let unpinned = (0, lodash_1.difference)(this.children, PINNED_PATHS);
            return [...pinned, ...unpinned];
        },
        isPinned() {
            return PINNED_PATHS.includes(this.pathBasename);
        },
        isActive() {
            return this.previewFile === this.path;
        },
    },
    watch: {},
    mounted() {
        this.expanded = this.expand || false;
        if (!this.preventChildLoad) {
            void this.loadPath();
        }
    },
    methods: {
        itemClicked() {
            if (this.itemError) {
                // @ts-ignore
                this.showError(this.path, this.itemError);
            }
            if (!this.fileInfo) {
                void this.loadPath();
                return;
            }
            if (this.type === 'DIRECTORY') {
                this.expanded = !this.expanded;
            }
            if (this.type === 'FILE') {
                // @ts-ignore
                this.openFile(this.path);
            }
        },
        _downloadFile() {
            console.log('download AA', this.path);
            // @ts-ignore
            this.downloadFile(this.path);
        },
        async loadPath() {
            try {
                this.loading = true;
                this.itemError = null;
                let res = await loadCharlyPath({
                    r4chost: this.r4chost,
                    cid: this.cid,
                    path: this.path,
                });
                if (res.success) {
                    this.fileInfo = res.fileInfo;
                    if (this.fileInfo) {
                        let { children, type } = this.fileInfo;
                        this.children = children;
                        this.type = type;
                    }
                }
                else {
                    this.itemError = res.error;
                }
            }
            catch (e) {
                (0, helpers_1.notifyError)(e);
            }
            finally {
                this.loading = false;
            }
        },
        formatFileSize(bytes) {
            let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0)
                return '0 Byte';
            let i = Math.floor(Math.log(bytes) / Math.log(1024));
            return parseFloat((bytes / Math.pow(1024, i)).toFixed(0)) + ' ' + sizes[i];
        },
    },
});
