"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerRoseGlobalDirectives = void 0;
const roseImageDirective_1 = require("./roseImageDirective");
const v_model_bind_view_state_1 = require("./v-model-bind-view-state");
const viewStateProviderFromRouter_1 = require("./viewStateProviderFromRouter");
function registerRoseGlobalDirectives({ Vue, router, viewStateProvider, metricsApi, }) {
    if (!viewStateProvider) {
        viewStateProvider = (0, viewStateProviderFromRouter_1.createViewStateProviderFromRouter)(router);
    }
    (0, v_model_bind_view_state_1.installModelBindRouteQueryDirective)(Vue, viewStateProvider);
    if (metricsApi) {
        (0, roseImageDirective_1.installRoseImageDirective)(Vue, metricsApi);
    }
}
exports.registerRoseGlobalDirectives = registerRoseGlobalDirectives;
