"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createViewStateProviderFromRouter = void 0;
const eventtarget_1 = require("../polyfills/eventtarget");
function createViewStateProviderFromRouter(router) {
    (0, eventtarget_1.loadEventTargetPolyFill)();
    // removes null entries
    function cleanQueryValue(viewStateEntry) {
        let cleaned;
        if (Array.isArray(viewStateEntry)) {
            cleaned = viewStateEntry.filter(entry => entry !== null);
        }
        else {
            cleaned = viewStateEntry;
        }
        return cleaned;
    }
    let viewStateEventTarget = new EventTarget();
    router.afterEach((to, from) => {
        let query = to.query;
        Object.keys(query).forEach(key => viewStateEventTarget.dispatchEvent(new CustomEvent(key, { detail: query[key] })));
    });
    return {
        getViewStateKey(key) {
            let viewStateEntry = router.currentRoute.query[key];
            return cleanQueryValue(viewStateEntry);
        },
        setViewStateKey(key, value, history) {
            let query = Object.assign(Object.assign({}, router.currentRoute.query), { [key]: value });
            if (history) {
                router.push({ query });
            }
            else {
                router.replace({ query });
            }
        },
        subscribeToViewStateKey(key, handler) {
            let internalHandler = (e) => handler(e.detail);
            viewStateEventTarget.addEventListener(key, internalHandler);
            return () => viewStateEventTarget.removeEventListener(key, internalHandler);
        },
    };
}
exports.createViewStateProviderFromRouter = createViewStateProviderFromRouter;
