"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        // change iban dialog
        changeIbanDialog: false,
        ibanValid: false,
        ibanElement: null,
    }),
    props: ['heading', 'stripeCustomerId', 'stripeCustomerName', 'stripeCustomerEmail'],
    watch: {},
    async mounted() { },
    computed: {
        stripeCustomer() {
            if (!this.stripeCustomerId) {
                return null;
            }
            return {
                id: this.stripeCustomerId,
                name: this.stripeCustomerName,
                email: this.stripeCustomerEmail,
            };
        },
    },
    methods: {
        async startChangeIban() {
            let changeIbanElm = this.$refs.changeIbanElm;
            changeIbanElm.startChangeIban();
        },
    },
});
