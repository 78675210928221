"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regelBaumGoz = exports.regelBaumBema = exports.getRegelIdsForEnv = exports.aaRegelMeta = exports.addAblehnungToMap = exports.setAblehnungen = exports.createAblehnungenMap = void 0;
const types_1 = require("../../types");
function createAblehnungenMap(ablehnungen) {
    let aInfos = {};
    for (const a of ablehnungen) {
        // init patid node
        if (!aInfos[a.patid]) {
            aInfos[a.patid] = { tage: {}, regeln: {} };
        }
        let tag = a.tagstring;
        if (tag) {
            if (!aInfos[a.patid].tage[tag]) {
                aInfos[a.patid].tage[tag] = { [a.regel]: a };
            }
            else {
                aInfos[a.patid].tage[tag][a.regel] = a;
            }
        }
        else {
            aInfos[a.patid].regeln[a.regel] = a;
        }
    }
    return aInfos;
}
exports.createAblehnungenMap = createAblehnungenMap;
/**
 * check if a vorschlag is ok or must be abgelehnt
 * @param a map of ablehnungen
 * @param v a vorschlag to test against map
 * return true if vorschlag is ok, false if vorschlag is abgelehnt
 */
function setAblehnungen(a, v) {
    v.ablehnung = undefined;
    const ap = a[v.patid];
    if (ap) {
        const ar = ap.regeln[v.regel];
        if (ar) {
            // ignore this rule for all days
            v.ablehnung = ar;
        }
        else {
            if (v.tagstring) {
                const tag = ap.tage[v.tagstring];
                if (tag && tag[v.regel]) {
                    // ignore that specific rule on that specific day
                    v.ablehnung = tag[v.regel];
                }
            }
        }
    }
}
exports.setAblehnungen = setAblehnungen;
function addAblehnungToMap(map, a, tag) {
    if (!map[a.patid]) {
        map[a.patid] = { tage: {}, regeln: {} };
    }
    if (tag) {
        if (!map[a.patid].tage) {
            map[a.patid].tage = {};
        }
        if (!map[a.patid].tage[tag]) {
            map[a.patid].tage[tag] = { [a.regel]: a };
        }
        else {
            map[a.patid].tage[tag][a.regel] = a;
        }
    }
    else {
        if (!map[a.patid].regeln) {
            map[a.patid].regeln = {};
        }
        map[a.patid].regeln[a.regel] = a;
    }
}
exports.addAblehnungToMap = addAblehnungToMap;
exports.aaRegelMeta = {
    egk_veraltet: {
        enabled: 'ALWAYS',
        name: 'El. Gesundheitskarte muss eingelesen werden',
        kurzName: 'EGK einlesen',
        beschreibung: `Die Elektronische Gesundheitskarte muss eingelesen werden`,
        beschreibungVorschlag: `Die Elektronische Gesundheitskarte muss eingelesen werden`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.AKTION,
        fehlendeLeistungen: [],
    },
    egk_ohne_bema: {
        enabled: 'ALWAYS',
        name: 'El. Gesundheitskarte eingelesen aber keine BEMA-Leistungen im Quartal',
        kurzName: 'EGK ohne BEMA',
        beschreibung: `Die eGK wurde eingelesen und es wurden noch keine BEMA-Leistungen im Quartal erbracht.`,
        beschreibungVorschlag: `Die eGK wurde eingelesen und es wurden noch keine BEMA-Leistungen im Quartal erbracht.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.AKTION,
        fehlendeLeistungen: [],
    },
    ae1_termin_ohne_leistungen_mit_goz_gkv: {
        enabled: 'ALWAYS',
        name: 'Ä1 Termin **ohne** BEMA Leistungen',
        kurzName: 'Ä1 Termin ohne BEMA',
        beschreibung: `Zu einem Termin werden nur GOZ Leistungen und keine BEMA Leistungen abgerechnet.`,
        beschreibungVorschlag: `Zu diesem Termin wurden **keine** BEMA, nur GOZ Leistungen abgerechnet.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 40,
        },
    },
    ae1_termin_ohne_leistungen_gkv: {
        enabled: 'ALWAYS',
        name: '**Ä1** Termin **ohne** Leistungen',
        kurzName: 'Ä1 Termin ohne Leistungen',
        beschreibung: `Zu einem Termin mit dem Status ok kann die Ä1 als alleinige Leistung abgerechnet werden. Bitte prüfen, ob nicht weitere Leistungen abzurechnen sind.`,
        beschreibungVorschlag: `Zu diesem Termin wurden **keine** Leistungen abgerechnet.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 40,
        },
    },
    ae1_erster_termin_im_quartal: {
        enabled: 'ALWAYS',
        name: '**Ä1** Erster Termin im Quartal',
        kurzName: 'Ä1 1. Termin im Quartal',
        beschreibung: `Die **Ä1** kann zum ersten Termin im Quartal abgerechnet werden.`,
        beschreibungVorschlag: `Die **Ä1** kann abgerechnet werden. Dies ist der erste Termin im Quartal.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 40,
        },
    },
    ae1_rezept_ohne_weitere_leistungen: {
        enabled: 'ALWAYS',
        name: '**Ä1** Rezept oder Heilmittelverordnung ohne weitere Leistungen',
        kurzName: 'Ä1 Rezept / HMV',
        beschreibung: `An einem Tag wurde ein Rezept oder eine Heilmittelverordnung ausgestellt, ohne Abrechnung der **Ä1** als alleinige Leistung.`,
        beschreibungVorschlag: `Rezept oder HMV ohne **Ä1** als alleinige Leistung.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 40,
        },
    },
    ae1_egk_eingelesen: {
        enabled: 'ALWAYS',
        name: '**Ä1** einlesen der EGK ohne weitere Leistungen',
        kurzName: 'Ä1 einlesen EGK',
        beschreibung: `An einem Tag wurde eine EGK eingelesen, ohne Abrechnung der **Ä1** als alleinige Leistung.`,
        beschreibungVorschlag: `EGK ohne **Ä1** als alleinige Leistung.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 40,
        },
    },
    '01': {
        enabled: 'ALWAYS',
        name: '**01** Eingehende Untersuchung',
        kurzName: '01 Eingehende Untersuchung',
        beschreibung: `Die **01** kann zum Termin abgerechnet werden. Eine Verrechnung mit der Ä1 wird ggf. berücksichtigt`,
        beschreibungVorschlag: `Die **01** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['01'],
        ersetzbareLeistungen: ['Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 30,
        },
    },
    '04': {
        enabled: 'ALWAYS',
        name: '**04** Erhebung des PSI-Code',
        kurzName: '04 PSI-Code',
        beschreibung: 'Der PSI (**04**) kann zum Termin erhoben werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['04'],
        konfiguration: {
            minAlter: 18,
        },
    },
    '107': {
        enabled: 'ALWAYS',
        name: '**107** Entfernen harter Zahnbeläge',
        kurzName: '107 Zahnstein',
        beschreibung: 'Zahnsteinentfernung (**107**) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['107'],
        konfiguration: {
            minAlter: 2,
        },
    },
    '111': {
        enabled: 'ALWAYS',
        name: '**111** Nachbehandlung im Rahmen der systematischen Behandlung von Parodontopathien',
        kurzName: '111 PAR-Nachbehandlung',
        beschreibung: 'PAR-Nachbehandlung (**111**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['111'],
    },
    '121': {
        enabled: 'ALWAYS',
        name: '**121** Beseitigung von Habits bei einem habituellen Distalbiss oder bei einem habituell offenen Biss',
        kurzName: '121 Beseitigung von Habits',
        beschreibung: 'Beseitigung von Habits (**121**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['121'],
    },
    '123a_ok': {
        enabled: 'ALWAYS',
        name: '**123a** Kieferorthopädische Maßnahmen mit herausnehmbaren Geräten zum Offenhalten von Lücken infolge vorzeitigem Milchzahnverlustes, **OK**',
        kurzName: '123a Lückenhalter OK',
        beschreibung: 'Der Patient verfügt über Lücken im Seitenzahnbereich im **Oberkiefer**, die in einem vorzeitigen Milchzahnverlust begründet sein können. Diese sollten mit einem Lückenhalter offen gehalten werden.',
        beschreibungVorschlag: 'Erstellung eines Lückenhalters im **OK**.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['123a'],
    },
    '123a_uk': {
        enabled: 'ALWAYS',
        name: '**123a** Kieferorthopädische Maßnahmen mit herausnehmbaren Geräten zum Offenhalten von Lücken infolge vorzeitigem Milchzahnverlustes, **UK**',
        kurzName: '123a Lückenhalter UK',
        beschreibung: 'Der Patient verfügt über Lücken im Seitenzahnbereich im **Unterkiefer**, die in einem vorzeitigen Milchzahnverlust begründet sein können. Diese sollten mit einem Lückenhalter offen gehalten werden.',
        beschreibungVorschlag: 'Erstellung eines Lückenhalters im **UK**.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['123a'],
    },
    '123b': {
        enabled: 'ALWAYS',
        name: '**123b** Kontrolle eines Lückenhalters',
        kurzName: '123b Kontrolle Lückenhalter',
        beschreibung: 'Kontrolle Lückenhalter (**123b**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['123b'],
    },
    fla: {
        enabled: 'ALWAYS',
        name: '**FLA** Fluoridlackanwendung zur Zahnschmelzhärtung',
        kurzName: 'FLA Fluoridlackanwendung',
        beschreibung: 'Fluoridlackanwendung (**FLA**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FLA'],
        ersetzbareLeistungen: ['10'],
    },
    fu2: {
        enabled: 'ALWAYS',
        name: 'FU2 Zahnärztliche Früherkennungsuntersuchung 34-72 Monate',
        kurzName: 'FU2 34-72 Monate',
        beschreibung: `Die **FU2** kann zum Termin abgerechnet werden. Eine Verrechnung mit der Ä1 oder 01 wird ggf. berücksichtigt`,
        beschreibungVorschlag: `Die **FU2** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FU2'],
        ersetzbareLeistungen: ['01', 'Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 10,
        },
    },
    fu1a: {
        enabled: 'ALWAYS',
        name: '**FU1a** Zahnärztliche Früherkennungsuntersuchung 6-9 Monate',
        kurzName: 'FU1a 6-9 Monate',
        beschreibung: `Die **FU1a** kann zum Termin abgerechnet werden. Eine Verrechnung mit der Ä1 oder 01 wird ggf. berücksichtigt`,
        beschreibungVorschlag: `Die **FU1a** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FU1a'],
        ersetzbareLeistungen: ['01', 'Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 20,
        },
    },
    fu1b: {
        enabled: 'ALWAYS',
        name: '**FU1b** Zahnärztliche Früherkennungsuntersuchung 10-20 Monate',
        kurzName: 'FU1b 10-20 Monate',
        beschreibung: `Die **FU1b** kann zum Termin abgerechnet werden. Eine Verrechnung mit der Ä1 oder 01 wird ggf. berücksichtigt`,
        beschreibungVorschlag: `Die **FU1b** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FU1b'],
        ersetzbareLeistungen: ['01', 'Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 20,
        },
    },
    fu1c: {
        enabled: 'ALWAYS',
        name: '**FU1c** Zahnärztliche Früherkennungsuntersuchung 21-33 Monate',
        kurzName: 'FU1c 21-33 Monate',
        beschreibung: `Die **FU1c** kann zum Termin abgerechnet werden. Eine Verrechnung mit der Ä1 oder 01 wird ggf. berücksichtigt`,
        beschreibungVorschlag: `Die **FU1c** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FU1c'],
        ersetzbareLeistungen: ['01', 'Ä1'],
        prio: {
            gruppe: '01fu',
            reihenfolge: 20,
        },
    },
    fupr: {
        enabled: 'ALWAYS',
        name: '**FUPr** Praktische Anleitung der Betreuungspersonen zur Mundhygiene beim Kind',
        kurzName: 'FUPr Praktische Anleitung',
        beschreibung: `Die **FUPr** kann zum Termin abgerechnet werden.`,
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['FUPr'],
    },
    ae1_termin_ohne_leistungen: {
        enabled: 'ONLYSTAGING',
        name: 'Ä1 - Termin ohne Leistungen',
        kurzName: 'Ä1 ohne',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
    },
    ae1: {
        enabled: 'ONLYSTAGING',
        name: 'Ä1 - Beratung',
        kurzName: 'Ä1 - Beratung',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä1'],
    },
    ae5: {
        enabled: 'ONLYSTAGING',
        name: 'Ä5 - Symptombezogene Untersuchung',
        kurzName: 'Ä5 - Symptombezogene Untersuchung',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä5'],
        prio: {
            gruppe: 'ae50010',
            reihenfolge: 20,
        },
    },
    ae500x: {
        enabled: 'ONLYSTAGING',
        name: 'Ä500x - Zähne, je Projektion x',
        kurzName: 'Ä500x',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä5000'],
    },
    ae925a_rb: {
        enabled: 'ALWAYS',
        name: '**Ä925a** (Rö2) Röntgendiagnostik der Zähne a) bis zwei Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä925a (Rö2) Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä925a** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik der Zähne a) bis zwei Aufnahmen (**Ä925a**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä925a'],
    },
    ae925b_rb: {
        enabled: 'ALWAYS',
        name: '**Ä925b** (Rö5) Röntgendiagnostik der Zähne b) bis fünf Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä925b (Rö5) Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä925b** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik der Zähne b) bis fünf Aufnahmen (**Ä925b**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä925b'],
    },
    ae925c_rb: {
        enabled: 'ALWAYS',
        name: '**Ä925c** (Rö8) Röntgendiagnostik der Zähne c) bis acht Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä925c (Rö8) Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä925c** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik der Zähne c) bis acht Aufnahmen (**Ä925c**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä925c'],
    },
    ae925d_rb: {
        enabled: 'ALWAYS',
        name: '**Ä925d** (Status) Röntgendiagnostik der Zähne d) Status bei mehr als acht Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä925d (Status) Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä925d** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik der Zähne d) Status bei mehr als acht Aufnahmen (**Ä925d**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä925d'],
    },
    ae925a_l: {
        enabled: 'ALWAYS',
        name: '**Ä925a** (Rö2) Röntgendiagnostik der Zähne a) bis zwei Aufnahmen (zu WF/Stift/ZE)',
        kurzName: 'Ä925a (Rö2) zu WF/Stift/ZE',
        beschreibung: '**Ä925a** (Rö2) zu WF/Stift/ZE',
        beschreibungVorschlag: '**Ä925a** (Rö2) zu WF/Stift/ZE',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['Ä925a'],
    },
    ae935a_rb: {
        enabled: 'ALWAYS',
        name: '**Ä935a** Teilaufnahme des Schädels des Ober- und Unterkiefers derselben Seite a) eine Aufnahme (Röntgenbuchabgleich)',
        kurzName: 'Ä935a Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä935a** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik (**Ä935a**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä935a'],
    },
    ae935b_rb: {
        enabled: 'ALWAYS',
        name: '**Ä935b** Teilaufnahme des Schädels des Ober- und Unterkiefers derselben Seite b) zwei Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä935b Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä935b** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik (**Ä935b**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä935b'],
    },
    ae935c_rb: {
        enabled: 'ALWAYS',
        name: '**Ä935c** Teilaufnahme des Schädels des Ober- und Unterkiefers derselben Seite c) mehr als zwei Aufnahmen (Röntgenbuchabgleich)',
        kurzName: 'Ä935c Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä935c** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik (**Ä935c**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä935c'],
    },
    ae935d_rb: {
        enabled: 'ALWAYS',
        name: '**Ä935d** Teilaufnahme des Schädels des Ober- und Unterkiefers derselben Seite d) Orthopantomogramm sowie Panoramaaufnahmen oder Halbseitenaufnahmen aller Zähne des Ober- und Unterkiefers (Röntgenbuchabgleich)',
        kurzName: 'Ä935d (OPG) Röntgenbuch',
        beschreibung: 'Die Anzahl der im Röntgenbuch erfassten Aufnahmen nach **Ä935d** stimmt nicht mit der Anzahl der erfassten Leistungen zu dieser Abrechnungsziffer in der Leistungserfassung überein.',
        beschreibungVorschlag: 'Röntgendiagnostik (**Ä935d**) wurde häufiger im Röntgenbuch eingetragen als abgerechnet.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['Ä935d'],
    },
    '0010_halbjahr_gkv': {
        enabled: 'ALWAYS',
        name: '0010 - Eingehende Untersuchung zur Feststellung von Zahn-, Mund- und Kiefererkrankungen einschließlich Erhebung des Parodontalbefundes sowie Aufzeichnung des Befundes (halbjährlich, wie 01 BEMA)',
        beschreibung: `Vorschlagen wie bei GKV: nicht im selben Halbjahr und mindestens 4 Monate her`,
        kurzName: '0010 (halbjährlich, wie 01 BEMA)',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['0010'],
        prio: { gruppe: 'ae50010', reihenfolge: 10 },
        auswahlgruppe: ['0010_quartal_pkv'],
    },
    '0010_quartal_pkv': {
        enabled: 'ALWAYS',
        name: '0010 - Eingehende Untersuchung zur Feststellung von Zahn-, Mund- und Kiefererkrankungen einschließlich Erhebung des Parodontalbefundes sowie Aufzeichnung des Befundes (quartalsweise)',
        kurzName: '0010 (quartalsweise)',
        beschreibung: `Vorschlagen wie bei PKV: nicht im selben Quartal, mindestens 30 Tage her`,
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['0010'],
        prio: { gruppe: 'ae50010', reihenfolge: 10 },
        auswahlgruppe: ['0010_halbjahr_gkv'],
    },
    '0070': {
        enabled: 'ONLYSTAGING',
        name: '0070 - Vitalitätsprüfung eines Zahnes oder mehrerer Zähne einschließlich Vergleichstest, je Sitzung',
        kurzName: '0070',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['0070'],
    },
    '0070cp': {
        enabled: 'ONLYSTAGING',
        name: '0070 - Vitalitätsprüfung eines Zahnes oder mehrerer Zähne einschließlich Vergleichstest, je Sitzung nach CP, P',
        kurzName: '0070 nach CP, P',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['0070'],
    },
    '0080': {
        enabled: 'ONLYSTAGING',
        name: '0080 - intraorale Oberflächenanästhesie, je Kieferhälfte oder Frontzahnbereich',
        kurzName: '0080',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['0080'],
    },
    '1010': {
        enabled: 'ONLYSTAGING',
        name: '1010 - Kontrolle des Übungserfolges einschließlich weiterer Unterweisung, Dauer mindestens 15 Minuten',
        kurzName: '1010',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['1010'],
    },
    '2030': {
        enabled: 'ONLYSTAGING',
        name: '2030 - Besondere Maßnahmen beim Präparieren oder Füllen von Kavitäten (z. B. Separieren, Beseitigen störenden Zahnfleisches, Stillung einer übermäßigen Papillenblutung), je Kieferhälfte oder Frontzahnbereich',
        kurzName: '2030',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2030'],
    },
    '2030prep': {
        enabled: 'ONLYSTAGING',
        name: '2030 - Besondere Maßnahmen beim Präparieren oder Füllen von Kavitäten (z. B. Separieren, Beseitigen störenden Zahnfleisches, Stillung einer übermäßigen Papillenblutung), je Kieferhälfte oder Frontzahnbereich (2x für Präp)',
        kurzName: '2030 Präp',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2030'],
    },
    '2040': {
        enabled: 'ONLYSTAGING',
        name: '2040 - Erneutes Anlegen von Spanngummi, je Kieferhälfte oder Frontzahnbereich',
        kurzName: '2040',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2040'],
    },
    '2197': {
        enabled: 'ONLYSTAGING',
        name: '2197 - Adhäsive Befestigung (plastischer Aufbau, Stift, Inlay, Krone, Teilkrone, Veneer, etc.)',
        kurzName: '2197',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2197'],
    },
    '2197fuellungen': {
        enabled: 'ONLYSTAGING',
        name: '2197 - Adhäsive Befestigung (plastischer Aufbau, Stift, Inlay, Krone, Teilkrone, Veneer, etc.) an Füllungen',
        kurzName: '2197',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2197'],
    },
    '2360': {
        enabled: 'ONLYSTAGING',
        name: '2360 - Exstirpation der vitalen Pulpa einschließlich Exkavieren, je Kanal',
        kurzName: '2360',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2360'],
    },
    '2400': {
        enabled: 'ONLYSTAGING',
        name: '2400 - Elektrometrische Längenbestimmung eines Wurzelkanals',
        kurzName: '2400',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2360', '2410', '2420', '2440'],
    },
    '2410': {
        enabled: 'ONLYSTAGING',
        name: '2410 - Aufbereitung eines Wurzelkanals auch retrograd, je Kanal, gegebenenfalls in mehreren Sitzungen',
        kurzName: '2410',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2360', '2420', '2440'],
    },
    '2420': {
        enabled: 'ONLYSTAGING',
        name: '2420 - Zusätzliche Anwendung elektrophysikalisch-chemischer Methoden, je Kanal',
        kurzName: '2420',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2360', '2410', '2440'],
    },
    '2440': {
        enabled: 'ONLYSTAGING',
        name: '2440 - Füllung eines Wurzelkanals',
        kurzName: '2440',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2360', '2410', '2420'],
    },
    '4005': {
        enabled: 'ALWAYS',
        name: '4005 - Erhebung mindestens eines Gingivalindex und/oder eines Parodontalindex (z. B. des Parodontalen Screening-Index PSI)',
        kurzName: '4005',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4005'],
    },
    '4020': {
        enabled: 'ONLYSTAGING',
        name: '4020 - Lokalbehandlung von Mundschleimhauterkrankungen gegebenenfalls einschließlich Taschenspülungen, je Sitzung',
        kurzName: '4020',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4020'],
    },
    '4025': {
        enabled: 'ONLYSTAGING',
        name: '4025 - Subgingivale medikamentöse antibakterielle Lokalapplikation, je Zahn',
        kurzName: '4025',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4025'],
    },
    '4050': {
        enabled: 'ONLYSTAGING',
        name: '4050 - Entfernung harter und weicher Zahnbeläge gegebenenfalls einschließlich Polieren an einem einwurzeligen Zahn oder Implantat, auch Brückenglied',
        kurzName: '4050',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4050'],
    },
    '4055': {
        enabled: 'ONLYSTAGING',
        name: '4055 - Entfernung harter und weicher Zahnbeläge gegebenenfalls einschließlich Polieren an einem mehrwurzeligen Zahn',
        kurzName: '4055',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4055'],
    },
    '4060': {
        enabled: 'ONLYSTAGING',
        name: '4060 - Kontrolle nach Entfernung harter und weicher Zahnbeläge oder professioneller Zahnreinigung nach der Nummer 1040 mit Nachreinigung einschließlich Polieren, je Zahn, oder Implantat, auch Brückenglied',
        kurzName: '4060',
        abrechnungsart: types_1.BemaGozType.GOZ,
        faktor: 2.3,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4060'],
    },
    ip4_1kh: {
        enabled: 'ALWAYS',
        name: '**IP4** Lokale Fluoridierung der Zähne (1x pro KH)',
        kurzName: 'IP4 Fluoridierung',
        beschreibung: 'Lokale Fluoridierung (**IP4**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['IP4'],
        ersetzbareLeistungen: ['10'],
    },
    ip4_2kh: {
        enabled: 'ALWAYS',
        name: '**IP4** Lokale Fluoridierung der Zähne bei erhöhtem Kariesrisiko',
        kurzName: 'IP4 Fluoridierung (Risiko)',
        beschreibung: 'Lokale Fluoridierung (**IP4**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['IP4'],
        ersetzbareLeistungen: ['10'],
    },
    ip1: {
        enabled: 'ALWAYS',
        name: '**IP1** Mundhygienestatus',
        kurzName: 'IP1 Mundhygienestatus',
        beschreibung: 'Mundhygienestatus (**IP1**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['IP1'],
    },
    ip2: {
        enabled: 'ALWAYS',
        name: '**IP2** Mundgesundheitsaufklärung bei Kindern und Jugendlichen',
        kurzName: 'IP2 Mundgesundheitsaufklärung',
        beschreibung: 'Mundgesundheitsaufklärung (**IP2**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['IP2'],
    },
    ip5: {
        enabled: 'ONLYSTAGING',
        name: '**IP5** Versiegelung von kariesfreien Fissuren und Grübchen der bleibenden Molaren (Zähne 6 und 7) mit aushärtenden Kunststoffen',
        kurzName: 'IP5 Versiegelung',
        beschreibung: 'Versiegelung (**IP5**) kann zum Termin abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['IP5'],
    },
    '13e': {
        enabled: 'ALWAYS',
        name: '**13e** einflächige Kompositfüllung im Seitenzahnbereich',
        kurzName: '13e Kompositfüllung SZ',
        beschreibung: 'Die einflächige Kompositfüllung (**13e**) kann bei Kindern und Jugendlichen bis zu einem Alter von 15 Jahren im Seitenzahnbereich anstelle der **13a** abgerechnet werden. Ggf. wird eine geleistete Zuzahlung nach **2060** zur **13a** verrechnet.',
        beschreibungVorschlag: 'Einflächige Kompositfüllung (**13e**) kann anstatt 13a abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['13e'],
        ersetzbareLeistungen: ['13a'],
    },
    '13f': {
        enabled: 'ALWAYS',
        name: '**13f** mehrflächige Kompositfüllung im Seitenzahnbereich',
        kurzName: '13f Kompositfüllung SZ',
        beschreibung: 'Die mehrflächige Kompositfüllung (**13f**) kann bei Kindern und Jugendlichen bis zu einem Alter von 15 Jahren im Seitenzahnbereich anstelle der **13b** abgerechnet werden. Ggf. wird eine geleistete Zuzahlung nach **2080** zur **13b** verrechnet.',
        beschreibungVorschlag: 'Mehrflächige Kompositfüllung (**13f**) kann anstatt **13b** abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['13f'],
        ersetzbareLeistungen: ['13b'],
    },
    '13g': {
        enabled: 'ALWAYS',
        name: '**13g** zweiflächige Kompositfüllung im Seitenzahnbereich',
        kurzName: '13g Kompositfüllung SZ',
        beschreibung: 'Die zweiflächige Kompositfüllung (**13g**) kann bei Kindern und Jugendlichen bis zu einem Alter von 15 Jahren im Seitenzahnbereich anstelle der **13c** abgerechnet werden. Ggf. wird eine geleistete Zuzahlung nach **2100** zur **13c** verrechnet.',
        beschreibungVorschlag: 'Zweiflächige Kompositfüllung (**13g**) kann anstatt **13c** abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['13g'],
        ersetzbareLeistungen: ['13c'],
    },
    '13h': {
        enabled: 'ALWAYS',
        name: '**13h** zweiflächige Kompositfüllung im Seitenzahnbereich',
        kurzName: '13h Kompositfüllung SZ',
        beschreibung: 'Die zweiflächige Kompositfüllung (**13h**) kann bei Kindern und Jugendlichen bis zu einem Alter von 15 Jahren im Seitenzahnbereich anstelle der **13d** abgerechnet werden. Ggf. wird eine geleistete Zuzahlung nach **2120** zur **13d** verrechnet.',
        beschreibungVorschlag: 'Zweiflächige Kompositfüllung (**13h**) kann anstatt **13d** abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['13h'],
        ersetzbareLeistungen: ['13d'],
    },
    '8_vor': {
        enabled: 'ALWAYS',
        name: '8 Sensibilitätsprüfung der Zähne (vor CP / P / Pulp / Endo / ZE)',
        kurzName: '8 vor (C)P / Pulp / Endo / ZE',
        beschreibung: 'Die Sensibilitätsprüfung (8) kann vor folgenden Behandlungen abgerechnet werden: CP / P / Pulp / Endo / ZE.',
        // beschreibungVorschlag: 'Die Sensibilitätsprüfung (8) kann zu ${referenzen} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['8'],
        prio: {
            gruppe: '8',
            reihenfolge: 10,
        },
    },
    '8_nach': {
        enabled: 'ALWAYS',
        name: '8 Sensibilitätsprüfung der Zähne (Kontrolle nach CP / P)',
        kurzName: '8 nach CP / P',
        beschreibung: 'Die Sensibilitätsprüfung (8) kann nach folgenden Behandlungen abgerechnet werden: CP / P.',
        // beschreibungVorschlag: 'Die Sensibilitätsprüfung (8) kann nach ${referenzen} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['8'],
        prio: {
            gruppe: '8',
            reihenfolge: 20,
        },
    },
    // '3010a': {
    //   enabled: 'ALWAYS',
    //   name: '**3010a** Corona-Hygiene-Pauschale "3010 analog - erhöhter Hygieneaufwand" analog § 6 Abs. 1, entsprechend Entfernung eines mehrwurzeligen Zahnes, 2,3x',
    //   kurzName: '3010a Corona-Hygiene-Pauschale, 2,3x',
    //   beschreibung: `Die Corona-Hygiene-Pauschale (**3010a**) kann abgerechnet werden, 2,3x`,
    //   abrechnungsart: BemaGozType.GOZ,
    //   faktor: 2.3,
    //   typ: AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
    //   fehlendeLeistungen: ['3010a'],
    //   konfiguration: { leistung: true, woAnbieten: { default: 'PKV', auswahl: ['GKV_PKV', 'PKV'] } },
    // },
    // '3010a_faktor1': {
    //   enabled: 'ALWAYS',
    //   name: '**3010a** Corona-Hygiene-Pauschale "3010 analog - erhöhter Hygieneaufwand" analog § 6 Abs. 1, entsprechend Entfernung eines mehrwurzeligen Zahnes, 1,0x',
    //   kurzName: '3010a Corona-Hygiene-Pauschale, 1,0x',
    //   beschreibung: `Die Corona-Hygiene-Pauschale (**3010a**) kann abgerechnet werden, 1,0x`,
    //   abrechnungsart: BemaGozType.GOZ,
    //   faktor: 1,
    //   typ: AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
    //   fehlendeLeistungen: ['3010a'],
    //   konfiguration: { leistung: true, woAnbieten: { default: 'PKV', auswahl: ['GKV_PKV', 'PKV'] } },
    // },
    // ae383a: {
    //   enabled: 'ALWAYS',
    //   name: '**ä383a** Corona-Hygiene-Pauschale "ä383 analog - erhöhter Hygieneaufwand"',
    //   kurzName: 'ä383a Corona-Hygiene-Pauschale',
    //   beschreibung: `Die Corona-Hygiene-Pauschale (**ä383a**) kann abgerechnet werden`,
    //   abrechnungsart: BemaGozType.GOZ,
    //   faktor: 1,
    //   typ: AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
    //   fehlendeLeistungen: ['ä383a'],
    //   konfiguration: { leistung: true, woAnbieten: { default: 'PKV', auswahl: ['GKV_PKV', 'PKV'] } },
    // },
    '2': {
        enabled: 'ALWAYS',
        name: '**2** Schriftliche Niederlegung eines Heil- und Kostenplanes',
        kurzName: '2 Erstellung HKP',
        beschreibung: 'Die schriftliche Niederlegung eines Heil- und Kostenplanes (**2**) kann zu HKP ${hkpid} abgerechnet werden.',
        beschreibungVorschlag: 'Erstellung HKP-KBR (**2**) kann zu HKP ${hkpid} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['2'],
    },
    '4': {
        enabled: 'ALWAYS',
        name: '**4** Befundaufnahme und Erstellen eines Heil- und Kostenplanes bei Erkrankungen der Mundschleimhaut und des Parodontiums',
        kurzName: '4 Erstellung PA-Status/HKP',
        beschreibung: 'Die Erstellung eines Heil- und Kostenplanes (**4**) kann zu HKP ${hkpid} abgerechnet werden.',
        beschreibungVorschlag: 'Erstellung PA-Status/HKP-PAR (**4**) kann zu HKP ${hkpid} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['4'],
    },
    '12': {
        enabled: 'ALWAYS',
        name: '**12** Besondere Maßnahmen beim Präparieren oder Füllen',
        kurzName: '12 BMF',
        beschreibung: 'Die BMF (**12**) kann zu folgenden Behandlungen abgerechnet werden: Flg / Stift / Endo / ZE',
        // beschreibungVorschlag: 'Die BMF (**12**) kann zu ${referenzen} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['12'],
        konfiguration: {
            triggerLeistungen: {
                default: [
                    '11',
                    '13b',
                    '13c',
                    '13d',
                    '13f',
                    '13g',
                    '13h',
                    '18a',
                    '18b',
                    '20a',
                    '20b',
                    '20c',
                    '27',
                    '32',
                    '34',
                    '91a',
                    '91b',
                    '91c',
                    '91d',
                    'ip5',
                ],
                auswahl: [],
            },
        },
    },
    '38': {
        enabled: 'ALWAYS',
        name: '**38** Nachbehandlung nach chirurgischem Eingriff oder Tamponieren oder dergleichen, je Kieferhälfte oder Frontzahnbereich, als selbständige Leistung, je Sitzung',
        kurzName: '38 Nachbehandlung',
        beschreibung: 'Die Nachbehandlung (**38**) kann abgerechnet werden.',
        // beschreibungVorschlag: 'Die Nachbehandlung (**38**) kann nach ${referenzen} abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['38'],
    },
    '40': {
        enabled: 'ONLYSTAGING',
        name: '**40** Infiltrationsanästhesie',
        kurzName: '40 Infiltrationsanästhesie',
        beschreibung: 'Eine Infiltrationsanästhesie (**40**) kann abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['40'],
    },
    '41a': {
        enabled: 'ONLYSTAGING',
        name: '**41a** Leitungsanästhesie',
        kurzName: '41a Leitungsanästhesie',
        beschreibung: 'Eine Leitungsanästhesie (**41a**) kann abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['41a'],
    },
    k7: {
        enabled: 'ALWAYS',
        name: '**KB** Kontrollbehandlung, ggf. mit einfachen Korrekturen des Aufbissbehelfs oder der Fixierung',
        kurzName: 'KB Kontrollbehandlung',
        beschreibung: 'Eine Kontrollbehandlung (**K7/K8**) kann abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.ABRECHNUNG,
        fehlendeLeistungen: ['K7', 'K8'],
    },
    beva: {
        enabled: 'ALWAYS',
        name: '**BEVa** - Befundevaluation',
        kurzName: 'BEVa - Befundevaluation',
        beschreibung: 'Befundevaluation (BEVa) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['BEVa'],
        ersetzbareLeistungen: ['Ä1'],
    },
    upta: {
        enabled: 'ALWAYS',
        name: '**UPTa** - Mundhygienekontrolle',
        kurzName: 'UPTa - Mundhygienekontrolle',
        beschreibung: 'Mundhygienekontrolle (UPTa) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTa'],
        ersetzbareLeistungen: ['Ä1'],
    },
    uptb: {
        enabled: 'ALWAYS',
        name: '**UPTb** - Mundhygieneunterweisung',
        kurzName: 'UPTb - Mundhygieneunterweisung',
        beschreibung: 'Mundhygieneunterweisung (UPTb) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTb'],
        ersetzbareLeistungen: ['Ä1'],
    },
    uptc: {
        enabled: 'ALWAYS',
        name: '**UPTc** - (Supra-)gingivale Reinigung aller Zähne von anhaftenden Biofilmen und Belägen, je Zahn',
        kurzName: 'UPTc - (Supra-)gingivale Reinigung aller Zähne',
        beschreibung: '(Supra-) gingivale Reinigung (UPTc) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTc'],
        ersetzbareLeistungen: ['105', '107', '107a'],
    },
    uptd: {
        enabled: 'ALWAYS',
        name: '**UPTd** - Messung von Sondierungsbluten und -tiefen',
        kurzName: 'UPTd - Messung von Sondierungsbluten und -tiefen',
        beschreibung: 'Messung von Sondierungsbluten/-tiefen (UPTd) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTd'],
        ersetzbareLeistungen: ['105', '107', '107a'],
    },
    upte: {
        enabled: 'ONLYSTAGING',
        name: '**UPTe** - Subgingivale Instrumentierung bei Sondierungstiefen von 4 mm oder mehr und Sondierungsbluten sowie an allen Stellen mit einer Sondierungstiefe von 5 mm oder mehr, je einwurzeligem Zahn',
        kurzName: 'UPTe - Subgingivale Instrumentierung einwurzelige Zähne',
        beschreibung: 'Subgingivale Instrumentierung in UPT (UPTe) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTe'],
    },
    uptf: {
        enabled: 'ONLYSTAGING',
        name: '**UPTf** - Subgingivale Instrumentierung bei Sondierungstiefen von 4 mm oder mehr und Sondierungsbluten sowie an allen Stellen mit einer Sondierungstiefe von 5 mm oder mehr, je mehrwurzeligem Zahn mm oder mehr, je einwurzeligem Zahn',
        kurzName: 'UPTf - Subgingivale Instrumentierung mehrwurzelige Zähne',
        beschreibung: 'Subgingivale Instrumentierung in UPT (UPTf) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTf'],
    },
    uptg: {
        enabled: 'ALWAYS',
        name: '**UPTg** - Untersuchung des Parodontalzustands',
        kurzName: 'UPTg - Untersuchung des Parodontalzustands',
        beschreibung: 'Untersuchung des Parodontalzustands (UPTg) kann zum Termin erbracht werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.BEHANDLUNG,
        fehlendeLeistungen: ['UPTg'],
        ersetzbareLeistungen: ['UPTd'],
    },
    gkv_ohnekch: {
        enabled: 'ALWAYS',
        name: 'GKV Patienten mit BEMA- ohne KCh-Leistungen',
        kurzName: 'GKV Patienten BEMA- ohne KCh-Leistungen',
        beschreibung: 'Noch keine KCh-Leistung im Quartal. Mit anderen BEMA-Leistungen, außer KCH',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.AKTION,
        fehlendeLeistungen: [],
    },
    gkv_ohnekch_relaxed: {
        enabled: 'ALWAYS',
        name: 'GKV Patienten mit Leistungen, ohne KCh-Leistungen',
        kurzName: 'GKV Patienten mit Leistungen, ohne KCh-Leistungen',
        beschreibung: 'Noch keine KCh-Leistung im Quartal. Mit anderen GOZ- oder BEMA-Leistungen, außer KCH',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.AKTION,
        fehlendeLeistungen: [],
    },
    // FEHLERHINWEISE
    // FEHLERHINWEISE
    // FEHLERHINWEISE
    '04_bei_pa': {
        enabled: 'ALWAYS',
        name: '04 während PAR-Behandlung',
        kurzName: '04 während PAR-Behandlung',
        beschreibung: 'Während einer PAR-Behandlung soll keine 04 abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '03_zu_befund': {
        enabled: 'ALWAYS',
        name: '01-Befund zum Notdienst (03)',
        kurzName: '01-Befund zum Notdienst (03)',
        beschreibung: 'Die eingehende Untersuchung (BEMA 01) ist nicht neben dem Zuschlag für Leistungen außerhalb der Sprechstunde (BEMA 03) berechnungsfähig.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '56c_zu_extraktion': {
        enabled: 'ALWAYS',
        name: 'Operation einer Zyste zu Extraktion',
        kurzName: 'Zy zur Ex',
        beschreibung: 'Die Entfernung einer Zyste zu einer Extraktion muss hinreichend dokumentiert werden und am besten pathologisch bestätigt werden. Die Operation einer Zyste zur Extraktion kann in Prüfungen zu Problemen führen.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '107_zu_105': {
        enabled: 'ALWAYS',
        name: 'Mundschleimhaus Behandlung (105) zu Zahnsteinentfernung (107)',
        kurzName: 'Mu (105) zur ZSt (107)',
        beschreibung: 'Die Erbringung der Mu (BEMA 105) zu Entfernen harter Zahnbeläge wird regelmäßig geprüft. Die Erforderlichkeit der Mu darf nicht aus der Erbringung der 107 resultieren. Sie muss gesondert zur Anwendung kommen und gut dokumentiert werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '107_zu_1040': {
        enabled: 'ALWAYS',
        name: 'Zahnsteinentfernung (107) zur Prophylaxe (1040)',
        kurzName: 'ZSt (107) zur PZR (1040)',
        beschreibung: 'Die Entfernung harter Beläge (107) ist in der Prophylaxe (GOZ 1040) bereits enthalten.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '107_zu_par': {
        enabled: 'ALWAYS',
        name: 'Zahnsteinentfernung (107) zu PAR-Termin',
        kurzName: 'ZSt (107) zu PAR',
        beschreibung: 'Die Entfernung harter Beläge (107) darf nicht am selben Tag wie Behandlungstermine der PAR-Strecke (MHU, ATG, AIT, CPT, UPT) abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    '107_und_04': {
        enabled: 'ALWAYS',
        name: 'Zahnsteinentfernung (107) zu PSI (04) ohne Zahnstein',
        kurzName: 'ZSt (107) zu PSI < 2',
        beschreibung: 'Der PSI zu diesem Datum ist für alle Sextanden < 2 eingetragen. Erst ab einem PSI >= 2 sind harte Beläge vorhanden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    ae1_zu_par: {
        enabled: 'ALWAYS',
        name: 'Beratung (Ä1) zu PAR-Termin',
        kurzName: 'Ä1 zu PAR',
        beschreibung: 'Die Beratung eines Kranken darf nicht am selben Tag wie Beratungs-/Evaluationstermine der PAR-Strecke (MHU, ATG, BEVa/b) abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    dentinadh_fuellung_aelter_als_14: {
        enabled: 'ALWAYS',
        name: 'Kompositfüllung bei Erwachsenen >14 Jahre',
        kurzName: 'Kompositfüllung >14J',
        beschreibung: 'Kompositfüllungen im Seitenzahnbereich (13e-h) dürfen nur bis zu einem Alter von 14 Jahren, bei schwangeren Frauen oder Vorhandensein einer entsprechenden Allergie abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    dentinadh_fuellung_in_front: {
        enabled: 'ALWAYS',
        name: 'Kompositfüllung im Frontzahnbereich',
        kurzName: 'Kompositfüllung in der Front',
        beschreibung: 'Kompositfüllungen im Seitenzahnbereich (13e-h) dürfen nur nicht im Frontzahnbereich abgerechnet werden.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    faktor_zu_hoch: {
        enabled: 'ALWAYS',
        name: 'Faktor über 3,5x',
        kurzName: 'Faktor >3,5x',
        beschreibung: 'Bei einem Faktor über 3,5x ist eine Honorarvereinbarung erforderlich.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    mehr_32_mal: {
        enabled: 'ALWAYS',
        name: 'Leistung mehr als 32 mal in Ansatz gebracht.',
        kurzName: 'Leistung >32',
        beschreibung: 'Die Leistung wurde mehr als 32 mal in Ansatz gebracht.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
    zahnarzttrio: {
        enabled: 'ALWAYS',
        name: 'Mu, SK, ÜZ',
        kurzName: 'Mu, SK, ÜZ',
        beschreibung: 'Die Erbringung der Leistungen Mu, SK, ÜZ in Kombination wird regelmäßig geprüft.',
        abrechnungsart: types_1.BemaGozType.BEMA,
        faktor: 1,
        typ: types_1.AbrechnungsVorschlaegeRegelType.FEHLER,
        fehlendeLeistungen: [],
    },
};
// get regel ids for enabled regeln
function getRegelIdsForEnv(env, settings, includeFilterPredicate = () => true) {
    let ret = [];
    for (const id in exports.aaRegelMeta) {
        if (Object.prototype.hasOwnProperty.call(exports.aaRegelMeta, id)) {
            const regel = exports.aaRegelMeta[id];
            const regelDeaktiviert = settings?.abrechnung?.regelSettings[id]?.deaktiviert || false;
            if (includeFilterPredicate(regel, id) &&
                !regelDeaktiviert &&
                (regel.enabled === 'ALWAYS' ||
                    (regel.enabled === 'ONLYSTAGING' && (env === 'development' || env === 'staging')))) {
                ret.push(id);
            }
        }
    }
    return ret;
}
exports.getRegelIdsForEnv = getRegelIdsForEnv;
// small helper to get rule
function m(id, active = false) {
    let meta = exports.aaRegelMeta[id];
    return meta.enabled ? { id, ...meta, active } : undefined;
}
function r(regeln) {
    return regeln.filter(x => !!x);
}
function regelBaumBema(active = false) {
    return [
        {
            name: 'Eingehende Untersuchung und Beratung',
            privat: false,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('ae1_termin_ohne_leistungen_mit_goz_gkv', active),
                m('ae1_termin_ohne_leistungen_gkv', active),
                m('ae1_erster_termin_im_quartal', active),
                m('ae1_rezept_ohne_weitere_leistungen', active),
                m('ae1_egk_eingelesen', active),
                m('01', active),
                m('8_vor', active),
                m('8_nach', active),
                m('04', active),
                m('egk_veraltet', active),
                m('egk_ohne_bema', active),
                m('gkv_ohnekch', active),
                m('gkv_ohnekch_relaxed', active),
            ]),
        },
        {
            name: 'Kinder und Jugendliche',
            privat: false,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('fu1a', active),
                m('fu1b', active),
                m('fu1c', active),
                m('fu2', active),
                m('fupr', active),
                m('ip1', active),
                m('ip2', active),
                m('ip4_1kh', active),
                m('ip4_2kh', active),
                m('ip5', active),
                m('fla', active),
            ]),
        },
        {
            name: 'Kons-Chirurgie',
            privat: false,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('12', active),
                m('13e', active),
                m('13f', active),
                m('13g', active),
                m('13h', active),
                m('38', active),
                m('40', active),
                m('41a', active),
                m('107', active),
            ]),
        },
        {
            name: 'PAR / KB / KFO',
            privat: false,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('2', active),
                m('4', active),
                m('111', active),
                m('121', active),
                m('123a_ok', active),
                m('123a_uk', active),
                m('123b', active),
                m('k7', active),
                m('beva', active),
                m('upta', active),
                m('uptb', active),
                m('uptc', active),
                m('uptd', active),
                // m('upte', active),
                // m('uptf', active),
                m('uptg', active),
            ]),
        },
        {
            name: 'Röntgen',
            privat: false,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('ae925a_rb', active),
                m('ae925b_rb', active),
                m('ae925c_rb', active),
                m('ae925d_rb', active),
                m('ae925a_l', active),
                m('ae935a_rb', active),
                m('ae935b_rb', active),
                m('ae935c_rb', active),
                m('ae935d_rb', active),
            ]),
        },
        {
            name: 'Prüfungs-Hinweise',
            privat: false,
            showEuroPotential: false,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([
                m('04_bei_pa', active),
                m('03_zu_befund', active),
                m('56c_zu_extraktion', active),
                m('107_zu_105', active),
                m('107_zu_1040', active),
                m('107_zu_par', active),
                m('107_und_04', active),
                m('ae1_zu_par', active),
                m('dentinadh_fuellung_aelter_als_14', active),
                m('dentinadh_fuellung_in_front', active),
                m('faktor_zu_hoch', active),
                m('mehr_32_mal', active),
                m('zahnarzttrio', active),
            ]),
        },
    ];
}
exports.regelBaumBema = regelBaumBema;
function regelBaumGoz(active = false, addBetaRules = false) {
    let ret = [
        // {
        //   name: 'Corona-Hygiene-Pauschale',
        //   privat: true,
        //   active,
        //   selection: active ? 'all' : 'none',
        //   regeln: r([m('3010a', active), m('3010a_faktor1', active), m('ae383a', active)]),
        // },
        {
            name: 'A. Allgemeine zahnärztliche Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([m('0010_halbjahr_gkv', active), m('0010_quartal_pkv', active), m('ae1', active), m('ae5', active)]),
        },
        {
            name: 'B. Prophylaktische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'C. Konservierende Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'D. Chirurgische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([m('4005', active)]),
        },
        {
            name: 'F. Prothetische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'G. Kieferorthopädische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'H. Eingliederung von Aufbißbehelfen und Schienen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'J. Funktionsanalytische und funktionstherapeutische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'K. Implantologische Leistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'L. Zuschläge',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'Ä. Leistungen nach GOÄ',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
        {
            name: 'V. Verlangensleistungen',
            privat: true,
            showEuroPotential: true,
            active,
            selection: active ? 'all' : 'none',
            regeln: r([]),
        },
    ];
    if (addBetaRules) {
        ret = [
            ...ret,
            {
                name: 'IN TEST',
                privat: true,
                showEuroPotential: true,
                active,
                selection: active ? 'all' : 'none',
                regeln: r([
                    m('ae1_termin_ohne_leistungen', active),
                    m('ae500x', active),
                    m('0070', active),
                    m('0070cp', active),
                    m('0080', active),
                    m('1010', active),
                    m('2030', active),
                    m('2030prep', active),
                    m('2040', active),
                    m('2197', active),
                    m('2197fuellungen', active),
                    m('2360', active),
                    m('2400', active),
                    m('2410', active),
                    m('2420', active),
                    m('2440', active),
                    m('4020', active),
                    m('4025', active),
                    m('4050', active),
                    m('4055', active),
                    m('4060', active),
                ]),
            },
        ];
    }
    return ret;
}
exports.regelBaumGoz = regelBaumGoz;
