"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        params: {},
    }),
    computed: {
        isTrue: {
            get() {
                return this.params.value;
            },
            set(value) {
                this.params.context.clicked({
                    row: this.params.data,
                    value,
                });
            },
        },
        label() {
            return this.params.context.label;
        },
    },
    methods: {
        clicked(event) {
            event.preventDefault();
            event.stopPropagation();
        },
    },
});
