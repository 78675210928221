"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.login = void 0;
const data_1 = require("./data");
const login = async (creds) => {
    const res = await data_1.metricsApi.auth.login(creds);
    if (!validateUserObject(res)) {
        throw new Error(`login failed, message: ${res.message}`);
    }
    const user = {
        email: res.email,
        cid: res.mainclientid,
        token: res.token,
        firstName: res.firstName,
        lastName: res.lastName,
        adminLevel: res.adminLevel,
    };
    return user;
};
exports.login = login;
function validateUserObject(user) {
    return user.cid && user.email && user.token;
}
