"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.installModelBindRouteQueryDirective = void 0;
function serializePrimitive(value, modifiers) {
    if (modifiers.json) {
        return JSON.stringify(value);
    }
    if (modifiers.boolean) {
        return String(!!value);
    }
    if (modifiers.number) {
        return String(value);
    }
    return String(value || '');
}
function deserializePrimitive(value, modifiers) {
    if (modifiers.number) {
        return +value;
    }
    if (modifiers.boolean) {
        return value.toString() === 'true';
    }
    if (modifiers.json) {
        return JSON.parse(value);
    }
    // default is string
    return value;
}
const elmUnsubscribeSymbol = Symbol('elmUnsubscribeSymbol');
/**
 * usage examples
 * make sure v-model binding exists! (standalone mode could probably be archived if requested)
 *
 * v-text-field(v-model="searchInput", v-model-bind-view-state="'search'")
 * v-switch(v-model="toggle", label="on/off", v-model-bind-view-state.boolean.history="'toggle'")
 * v-select(:items="['a','b', 'c']", v-model="select", multiple, v-model-bind-view-state.array="'selected'")
 * v-range-slider(v-model="range", style="width: 200px", v-model-bind-view-state.number.array="'range'")
 *
 * Modifiers:
 * default primitive type is string
 * .boolean
 * .number
 * .json
 *
 * if v-model binds to an array specify .array aswell as the primitive type
 * .array
 *
 * default mode replaces route, use .history to push a new history entry on model change (currently not fully implemented)
 * .history
 *
 */
function installModelBindRouteQueryDirective(vue, viewStateProvider) {
    vue.directive('model-bind-view-state', {
        bind(el, binding, vnode) {
            let bindingValue = binding.value;
            let viewStateKey;
            if (typeof bindingValue === 'string') {
                viewStateKey = bindingValue;
            }
            else {
                viewStateKey = bindingValue.viewStateKey;
            }
            let setViewState = (newValue) => {
                let serialized;
                if (binding.modifiers.array) {
                    serialized = newValue.map(value => serializePrimitive(value, binding.modifiers));
                }
                else {
                    serialized = serializePrimitive(newValue, binding.modifiers);
                }
                // check if route needs to be updated
                if (String(viewStateProvider.getViewStateKey(viewStateKey)) !== String(serialized)) {
                    // console.log('viewStateProvider.setViewStateKey', viewStateKey, serialized);
                    viewStateProvider.setViewStateKey(viewStateKey, serialized, binding.modifiers.history);
                }
            };
            // listen to v-model updates and update url
            // vue component
            if (vnode.componentInstance) {
                vnode.componentInstance.$on('change', setViewState);
            }
            else {
                // native element
                el.addEventListener('change', (e) => setViewState(e.target.value));
            }
            function setModelValue(viewStateValue) {
                if (!viewStateValue) {
                    return;
                }
                let value = viewStateValue;
                if (binding.modifiers.array) {
                    if (!Array.isArray(viewStateValue)) {
                        viewStateValue = [viewStateValue];
                    }
                    value = viewStateValue.map(elm => deserializePrimitive(elm, binding.modifiers));
                }
                else {
                    value = deserializePrimitive(viewStateValue, binding.modifiers);
                }
                if (vnode.componentInstance) {
                    vnode.componentInstance.$emit('change', value);
                    vnode.componentInstance.$emit('input', value);
                }
                else {
                    el.value = String(value);
                    el.dispatchEvent(new CustomEvent('input'));
                }
            }
            // listen to viewState updates and update the model
            let unsub = viewStateProvider.subscribeToViewStateKey(viewStateKey, newViewStateValue => {
                // console.log('new view state value', viewStateKey, newViewStateValue);
                setModelValue(newViewStateValue);
            });
            // @ts-ignore
            el[elmUnsubscribeSymbol] = unsub;
            // check the initial viewState value and update the model
            let initialViewStateValue = viewStateProvider.getViewStateKey(viewStateKey);
            if (initialViewStateValue) {
                // wait for component init to react to change
                setTimeout(() => {
                    setModelValue(initialViewStateValue);
                });
            }
        },
        unbind(el, binding, vnode) {
            // @ts-ignore
            el[elmUnsubscribeSymbol]();
        },
    });
}
exports.installModelBindRouteQueryDirective = installModelBindRouteQueryDirective;
