"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toastStore = void 0;
const vue_1 = require("vue");
const notifyBus_1 = require("@/../../common-ui/src/services/notifyBus");
exports.toastStore = (0, vue_1.reactive)({
    showToast: false,
    toastText: null,
    setToast(toastText) {
        this.showToast = true;
        this.toastText = toastText;
    },
    setShowToast(display) {
        this.showToast = display;
    },
});
(0, notifyBus_1.registerNotifyHandler)({
    notifySuccess: msg => exports.toastStore.setToast(msg),
    notifyError: msg => exports.toastStore.setToast(msg),
});
