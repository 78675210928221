"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.importStateStore = void 0;
const vue_1 = require("vue");
exports.importStateStore = (0, vue_1.reactive)({
    importCount: -1,
    setImportCount(importCount) {
        this.importCount = importCount;
    },
});
