"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigHKP = exports.createHkpNode = void 0;
const types_1 = require("../../../types");
function createHkpNode() {
    let kassePrivat = {
        Kasse: {
            hkpKasseZE: 'ZE',
            hkpKasseKBrFAL: 'KBr',
            hkpKassePA: 'PA',
            hkpKasseKFO: 'KFO',
        },
        Privat: {
            hkpPrivatZE: 'ZE',
            hkpPrivatKBrFAL: 'KBr / FAL',
            hkpPrivatPA: 'PA / GTR',
            hkpPrivatKFO: 'KFO',
            hkpPrivatImpla: 'Impla',
            hkpPrivatAllgemein: 'Allgemein',
        },
    };
    let status = {
        eingabe: 'Eingabe',
        genehmigt: 'Genehmigt',
        durchgefuehrt: 'Durchgeführt',
        verworfen: 'Verworfen',
        abgelehnt: 'Abgelehnt',
    };
    function createStatusNode(part, // erstellt oder status
    statusName, // e.g. Eingabe
    statusShortName, // e.g. Eingabe
    statusKey, // e.g. eingabe
    statusParam, // e.g. betrag
    smartValueRef, // 100% reference
    unit, collapsed, parentNodehelp) {
        let parentNode = {
            name: statusName,
            shortName: statusShortName,
            unit,
            smartValue: `s / (${smartValueRef}) * 100`,
            smartUnit: '%',
            debug: [`${part}_hkpKasse.${statusKey}.ids`, `${part}_hkpPrivat.${statusKey}.ids`],
            value: `b.${part}_hkpKasse.${statusKey}.${statusParam} + b.${part}_hkpPrivat.${statusKey}.${statusParam}`,
            virtual: true,
            collapsed,
            dividedClasses: ['hkp-indent'],
            help: parentNodehelp,
            childs: {},
            noHistory: true,
        };
        for (const kp in kassePrivat) {
            if (Object.prototype.hasOwnProperty.call(kassePrivat, kp)) {
                // Kasse/Privat
                const kpKey = `_${part}_hkp_${kp.toLowerCase()}_${statusKey}_${statusParam}`;
                const childs = parentNode.childs;
                if (childs) {
                    childs[kpKey] = {
                        name: kp,
                        value: `b.${part}_hkp${kp}.${statusKey}.${statusParam}`,
                        virtual: true,
                        smartValue: `s / (${parentNode.value}) * 100`,
                        smartUnit: '%',
                        dividedClasses: ['hkp-indent'],
                        debug: [`${part}_hkp${kp}.${statusKey}.ids`],
                        childs: {},
                        noHistory: true,
                    };
                    const arten = kassePrivat[kp];
                    if (arten) {
                        for (const art in arten) {
                            if (Object.prototype.hasOwnProperty.call(arten, art)) {
                                const cchilds = childs[kpKey].childs;
                                if (cchilds) {
                                    cchilds[`_${part}_hkp_${art}_${statusKey}_${statusParam}`] = {
                                        name: arten[art],
                                        value: `b.${part}_${art}.${statusKey}.${statusParam}`,
                                        virtual: true,
                                        smartValue: `s /(${childs[kpKey].value}) * 100`,
                                        smartUnit: '%',
                                        debug: [`${art}.${statusKey}.ids`],
                                        dividedClasses: ['hkp-indent'],
                                        noHistory: true,
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
        return parentNode;
    }
    function createMainNode(name, shortName, valueParam, statusParam, unit, parentNodeHelp) {
        let parentNode = {
            name,
            shortName,
            unit,
            smartValue: '100',
            smartUnit: '%',
            value: valueParam,
            virtual: true,
            childs: {},
            help: parentNodeHelp,
            noHistory: true,
        };
        for (const s in status) {
            if (Object.prototype.hasOwnProperty.call(status, s)) {
                const statusName = status[s];
                const childs = parentNode.childs;
                if (childs) {
                    childs[`_hkp_${statusParam}_${s}`] = createStatusNode('status', statusName, undefined, s, statusParam, valueParam, unit, true);
                }
            }
        }
        return parentNode;
    }
    let hkpNode = {
        name: 'HKPs',
        isHeader: true,
        value: 'b.hkp.gesamt.count',
        unit: 'hkp',
        virtual: true,
        childs: {
            _hkp_erstellt_count: createStatusNode('erstellt', 'Erstellte HKPs - Anzahl', 'HKPs #', 'gesamt', 'count', 's', 'hkp', false, 'Anzahl der im Betrachtungszeitraum neu erstellten HKPs, unabhängig davon in welchen Status sie sich befinden.'),
            _hkp_erstellt_betrag: createStatusNode('erstellt', 'Erstellte HKPs - Beträge', 'HKPs €', 'gesamt', 'betrag', 's', 'eur', false, 'Summenmäßige Beträge (inkl. Honorar, Labor, Material etc.) der im Betrachtungszeitraum neu erstellten HKPs, unabhängig davon in welchen Status sie sich befinden.'),
            _hkp_status_betrag: createMainNode('HKPs nach aktuellem Status - Anzahl', 'Status #', 'b.status_hkp.gesamt.count', 'count', 'hkp', `Anzahl der HKPs, die im Betrachtungszeitraum den Status **Eingabe** oder **genehmigt** hatten oder **während** des Zeitraums auf den Status **durchgeführt**, **verworfen** oder **abgelehnt** gesetzt wurden.

Jeder HKP wird nur einmal gezählt, es zählt immer der höhere Status Eingabe -> genehmigt -> durchgeführt/verworfen/abgelehnt.`),
            _hkp_status_count: createMainNode('HKPs nach aktuellem Status - Beträge', 'Status €', 'b.status_hkp.gesamt.betrag', 'betrag', 'eur', `Summenmäßige Beträge (inkl. Honorar, Labor, Material etc.) der HKPs, die im Betrachtungszeitraum den Status **Eingabe** oder **genehmigt** hatten oder während des Zeitraums auf den Status **durchgeführt**, **verworfen** oder **abgelehnt** gesetzt wurden.

Jeder HKP wird nur einmal gezählt, es zählt immer der höhere Status Eingabe -> genehmigt -> durchgeführt/verworfen/abgelehnt.`),
            _sepHkpTage: {
                name: 'Bearbeitungszeit',
                virtual: true,
                isHeader: true,
                noHistory: true,
                childs: {
                    hkpTageBisGenehmigt: {
                        name: 'Tage Erstellung - Genehmigung',
                        unit: 'tage',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        noAvg: true,
                        noHistory: true,
                    },
                    hkpTageBisDurchgefuehrt: {
                        name: 'Tage Genehmigung - Durchführung',
                        unit: 'tage',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        noAvg: true,
                        noHistory: true,
                    },
                    hkpTageErstelltBisDurchgefuehrt: {
                        name: 'Tage Erstellung - Durchführung',
                        unit: 'tage',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        noAvg: true,
                        noHistory: true,
                    },
                },
            },
        },
    };
    return hkpNode;
}
exports.createHkpNode = createHkpNode;
function benchmarkConfigHKP() {
    return createHkpNode();
}
exports.benchmarkConfigHKP = benchmarkConfigHKP;
