"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const common_ui_1 = require("@rose/common-ui");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        search: common_ui_1.isDev ? 'anamnese_task_v2228l5t' : '',
        searchCid: '',
        loading: false,
        searchResults: [],
    }),
    computed: {},
    async mounted() {
        console.log('DebugSuche mounted');
    },
    methods: {
        async doSeach() {
            console.log('doSeach', this.search);
            try {
                this.loading = true;
                let res = (await data_1.metricsApi.admin.debugSearch({ search: this.search, searchCid: this.searchCid }));
                res.sort((a, b) => b.res.length - a.res.length);
                this.searchResults = res;
                console.log('res', res);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                this.loading = false;
            }
        },
        getColumns(res) {
            let pivot = res[0];
            if (!pivot)
                return [];
            return Object.keys(pivot);
        },
        toStringWithTrucate(obj) {
            if (typeof obj === 'string')
                return this.truncate(obj);
            let s = JSON.stringify(obj);
            return this.truncate(s);
        },
        truncate(str, n = 100) {
            return str.length > n ? str.substr(0, n - 1) + '...' : str;
        },
        log(item) {
            (0, common_ui_1.logVueReactiveObject)('log', item);
        },
    },
});
