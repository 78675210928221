"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
const data_1 = require("@/services/data");
exports.default = (0, vue_1.defineComponent)({
    name: 'EditProfileGroupDialogue',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        profileGroup: {
            type: Object,
            default: null,
        },
        availableClusters: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        modifiedProfileGroup: {
            name: '',
            dwh: {
                contact: {
                    name: '',
                    email: '',
                    phone: '',
                },
                clusters: [],
            },
        },
        ibanValid: false,
        ibanElement: {},
    }),
    computed: {
        showDialogue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        isValid() {
            var _a, _b, _c;
            return (this.modifiedProfileGroup.name &&
                ((_c = (_b = (_a = this.modifiedProfileGroup) === null || _a === void 0 ? void 0 : _a.dwh) === null || _b === void 0 ? void 0 : _b.contact) === null || _c === void 0 ? void 0 : _c.name) &&
                this.modifiedProfileGroup.dwh.contact.email &&
                this.modifiedProfileGroup.dwh.contact.phone);
        },
    },
    watch: {
        showDialogue(value) {
            if (value) {
                this.initProfileGroup();
            }
        },
    },
    methods: {
        initProfileGroup() {
            if (this.profileGroup) {
                this.modifiedProfileGroup = Object.assign({}, this.profileGroup);
            }
            else {
                this.modifiedProfileGroup = {
                    id: 0,
                    name: '',
                    dwh: {
                        contact: {
                            name: '',
                            email: '',
                            phone: '',
                        },
                        clusters: [],
                    },
                };
            }
            console.log('modifiedProfileGroup', this.modifiedProfileGroup);
        },
        async save() {
            try {
                await (0, helpers_1.handleApiCall)({
                    apiFunction: () => data_1.metricsApi.profile.saveProfileGroup(this.modifiedProfileGroup),
                    successMessage: `Profile Group ${this.modifiedProfileGroup.id ? 'updated' : 'created'}`,
                    successCallback: () => {
                        (0, helpers_1.notifySuccess)('Saved');
                    },
                });
                this.$emit('save');
                this.showDialogue = false;
            }
            catch (e) {
                (0, helpers_1.notifyError)(`Error! ${e.message}`);
            }
        },
    },
});
