"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
const data_1 = require("@/services/data");
exports.default = (0, vue_1.defineComponent)({
    name: 'ContactPositionCell',
    data: () => ({
        params: {},
        position: ['IT', 'Praxismanager', 'Arzt', 'Inhaber', 'Sammelkontakt', 'Verwaltung', 'Empfang'],
    }),
    methods: {
        async setPosition(position) {
            var _a;
            if ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.id) {
                const id = this.params.data.id;
                await (0, helpers_1.handleApiCall)({
                    apiFunction: () => data_1.metricsApi.helpdesk.updateContact({ id, job_title: position }),
                    successMessage: 'Der Kontakt wurde erfolgreich aktualisiert',
                    successCallback: () => {
                        if (this.params.setValue)
                            this.params.setValue(position);
                    },
                });
            }
        },
    },
});
