"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionOverviewColumns = void 0;
const common_ui_1 = require("@rose/common-ui");
function subscriptionOverviewColumns(smallScreen) {
    return [
        {
            headerName: 'Client-ID',
            field: 'cid',
            cellRenderer: 'ClientsClientIdCell',
            headerTooltip: 'Eindeutige ID des Kunden',
            width: 300,
            pinned: smallScreen ? undefined : 'left',
        },
        {
            headerName: 'rose',
            headerTooltip: 'rose',
            children: [
                {
                    headerName: 'Praxis',
                    field: 'praxisKurzName',
                    headerTooltip: 'Praxisname',
                    width: 250,
                },
                {
                    headerName: 'Tags',
                    field: 'tags',
                    headerTooltip: 'Tags',
                    width: 80,
                },
                {
                    headerName: 'Lizenz-Bemerkung',
                    field: 'lizenzbemerkung',
                    headerTooltip: 'Bemerkung der Lizenz',
                    width: 120,
                },
                {
                    headerName: 'Feature',
                    field: 'rosefeature',
                    headerTooltip: 'Name des Features',
                    width: 120,
                },
                {
                    headerName: 'Abo-Start',
                    field: 'rosefrom',
                    sort: 'asc',
                    headerTooltip: 'Datum des Abo-Starts',
                    valueFormatter: common_ui_1.dateFormatter,
                    width: 110,
                },
                {
                    headerName: 'Abo-Ende',
                    field: 'roseto',
                    headerTooltip: 'Datum des Abo-Endes',
                    valueFormatter: common_ui_1.dateFormatter,
                    width: 110,
                },
                {
                    headerName: 'Abo-Status',
                    field: 'abostatus',
                    cellRenderer: 'AboStatusCell',
                    headerTooltip: 'Status des Abos',
                    width: 100,
                },
                {
                    headerName: 'Payment',
                    valueGetter: params => { var _a; return !!((_a = params.data) === null || _a === void 0 ? void 0 : _a.haspaymentmethod); },
                    cellRenderer: 'BooleanIconCell',
                    headerTooltip: 'Hat Zahlungsmethode',
                    width: 80,
                },
            ],
        },
        {
            headerName: 'Onboarding',
            headerTooltip: 'Onboarding',
            children: [
                {
                    headerName: 'Datum',
                    field: 'obcreatedate',
                    width: 110,
                    headerTooltip: 'Datum des Onboardings',
                    valueFormatter: common_ui_1.dateFormatter,
                },
                {
                    headerName: 'Status',
                    field: 'obstatus',
                    width: 110,
                    cellRenderer: 'OnboardingMainStatusCell',
                    headerTooltip: 'Status des Onboardings',
                },
                {
                    headerName: 'Prozess-Status',
                    field: 'obprocessstatus',
                    width: 120,
                    cellRenderer: 'OnboardingProcessStatusCell',
                    headerTooltip: 'Status des Onboarding-Prozesses',
                },
            ],
        },
        {
            headerName: 'Stripe',
            headerTooltip: 'Stripe',
            children: [
                {
                    headerName: 'SubCount',
                    headerTooltip: 'Anzahl der Abos',
                    width: 80,
                    field: 'subscriptioncount',
                    valueFormatter: params => (params.value ? params.value : '-'),
                },
                {
                    headerName: 'Abo-Start',
                    field: 'stripefrom',
                    headerTooltip: 'Datum des Abo-Starts',
                    valueFormatter: common_ui_1.dateFormatter,
                    width: 110,
                },
                {
                    headerName: 'Abo-Ende',
                    field: 'stripeto',
                    headerTooltip: 'Datum des Abo-Endes',
                    valueFormatter: common_ui_1.dateFormatter,
                    width: 110,
                },
                {
                    headerName: 'Abo-Paket',
                    field: 'stripefeature',
                    headerTooltip: 'Stripe Abo',
                    width: 150,
                },
                {
                    headerName: 'Abo-ID',
                    field: 'subscriptionid',
                    cellRenderer: 'ClientsClientIdCell',
                    headerTooltip: 'Stripe ID',
                    width: 100,
                },
            ],
            flex: 1,
        },
    ];
}
exports.subscriptionOverviewColumns = subscriptionOverviewColumns;
