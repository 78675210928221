"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        statusText: undefined,
    }),
    async mounted() {
        this.fetchStatusText();
    },
    methods: {
        // status text
        async fetchStatusText() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.getUiAlertStatus)(),
                successMessage: null,
                errorMessage: 'Statusnachricht konnte nicht geladen werden',
                successCallback: response => {
                    // cast to boolean
                    this.statusText = !!response.message;
                },
            });
        },
        async setStatus() {
            let response = prompt('Geben Sie eine Statusnachricht ein');
            if (response) {
                await (0, helpers_1.handleApiCall)({
                    apiFunction: () => (0, data_1.setUiAlertStatus)(response),
                    successMessage: 'Statusnachricht gesetzt',
                });
                this.fetchStatusText();
            }
        },
        async deleteStatus() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.deleteUiAlertStatus)(),
                successMessage: 'Statusnachricht entfernt',
            });
            this.fetchStatusText();
        },
    },
});
