"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionSubscriptionColumns = void 0;
const common_ui_1 = require("@rose/common-ui");
function subscriptionSubscriptionColumns(smallScreen) {
    return [
        {
            headerName: 'Customer-ID',
            field: 'customer',
            sort: 'asc',
            cellRenderer: 'ClientsClientIdCell',
            headerTooltip: 'Eindeutige ID des Kunden',
            width: 170,
            pinned: smallScreen ? undefined : 'left',
        },
        {
            headerName: 'Subscription-ID',
            field: 'id',
            headerTooltip: 'Eindeutige ID des Abonnements',
            width: 240,
        },
        {
            headerName: 'Features',
            field: 'features',
            headerTooltip: 'Features des Abonnements',
            width: 200,
            valueFormatter: common_ui_1.arrayFormatter,
        },
        {
            headerName: 'Testzeitraum-Start',
            field: 'trialstart',
            headerTooltip: 'Start des Testzeitraums',
            width: 140,
            valueFormatter: common_ui_1.dateFormatter,
        },
        {
            headerName: 'Testzeitraum-Ende',
            field: 'trialend',
            headerTooltip: 'Ende des Testzeitraums',
            width: 140,
            valueFormatter: common_ui_1.dateFormatter,
        },
        {
            headerName: 'Start',
            field: 'start',
            headerTooltip: 'Start des Abonnements',
            width: 140,
            valueFormatter: common_ui_1.dateFormatter,
        },
        {
            headerName: 'Ende',
            field: 'cancelAtDate',
            headerTooltip: 'Ende des Abonnements',
            width: 140,
            valueFormatter: common_ui_1.dateFormatter,
        },
        {
            headerName: 'gekündigt',
            field: 'cancelAtPeriodEnd',
            headerTooltip: 'Wird das Abonnement gekündigt?',
            width: 100,
        },
        {
            headerName: 'gekündigt am',
            field: 'cancelledAt',
            headerTooltip: 'Kündigungsdatum',
            width: 140,
            valueFormatter: common_ui_1.dateFormatter,
            flex: 1,
        },
    ];
}
exports.subscriptionSubscriptionColumns = subscriptionSubscriptionColumns;
