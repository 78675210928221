"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'GoToCell',
    data: () => ({
        params: {},
        clientid: '',
    }),
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        this.setValues();
    },
    methods: {
        setValues() {
            this.clientid = this.params.value;
        },
        clicked() {
            void this.$router.push('/users/?search=' + this.clientid);
        },
    },
});
