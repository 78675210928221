"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("./helpers");
const notifyBus_1 = require("../services/notifyBus");
exports.default = (0, vue_1.defineComponent)({
    props: ['value', 'formatter', 'scope', 'path', 'className', 'copyText'],
    computed: {
        displayValue() {
            let value = this.value;
            if (this.scope) {
                value = this.scope.row;
            }
            value = (0, helpers_1.walkPropertyPath)(value, this.path);
            if (this.formatter) {
                return this.formatter({ prop: value }, { property: 'prop' });
            }
            return value;
        },
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
    },
    methods: {
        copySuccess() {
            (0, notifyBus_1.notifySuccess)('Copied!');
        },
        copyError() {
            (0, notifyBus_1.notifyError)('Copy failed: ' + this.displayValue);
        },
    },
});
