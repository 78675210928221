"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerNotifyHandler = exports.notifyError = exports.notifySuccess = void 0;
const tiny_emitter_1 = require("tiny-emitter");
const notifyBus = new tiny_emitter_1.TinyEmitter();
function notifySuccess(message) {
    notifyBus.emit('success', message);
}
exports.notifySuccess = notifySuccess;
function notifyError(message) {
    notifyBus.emit('error', message);
}
exports.notifyError = notifyError;
function registerNotifyHandler(handler) {
    notifyBus.on('success', handler.notifySuccess);
    notifyBus.on('error', handler.notifyError);
}
exports.registerNotifyHandler = registerNotifyHandler;
