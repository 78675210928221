"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPapoApi = void 0;
const config_1 = require("../../config");
function createPapoApi(api) {
    return {
        getShareInfos: (shareId) => api.r4cDispatch.get(`papo/shareInfos/${shareId}`),
        createPapoAccount: (params) => api.r4cDispatch.post(`papo/createPapoAccount`, params),
        resetPassword: (params) => api.r4cDispatch.post(`papo/resetPassword`, params),
        resetPasswordConfirm: (params) => api.r4cDispatch.post(`papo/resetPasswordConfirm`, params),
        papoLogin: (params) => api.r4cDispatch.post(`papo/papoLogin`, params),
        getShareEntries: () => api.r4cDispatch.get(`papo/getShareEntries`),
        linkpatient: (params) => api.r4cDispatch.post(`papo/linkpatient`, params),
        getDownloadLink: (fileId, token) => `${(0, config_1.getR4cDispatcherUrl)()}/papo/download/${fileId}?jwt=${token}`,
        // praxis admin / desktop-r4c routes
        adminGetSharedDocumentsInfos: () => api.r4cDispatch.get(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedDocumentsInfos`),
        adminGetSharedTasks: () => api.r4cDispatch.get(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedTasksInfos`),
        checkMailSendIds: (messageIds) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/checkMailSendIds`, {
            messageIds,
        }),
        adminResentShareEmailDocument: (shareId, overrideMail) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResentShareEmailDocument`, {
            shareId,
            overrideMail,
        }),
        adminResentShareEmailTask: (shareId, overrideMail) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResentShareEmailTask`, { shareId, overrideMail }),
        adminResetShareIdDocument: (shareId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResetShareIdDocument`, { shareId }),
        adminResetShareIdTask: (shareId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResetShareIdTask`, { shareId }),
        adminDeleteSharedFile: (fileId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminDeleteSharedFile`, { fileId }),
        adminDeleteSharedTask: (taskId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminDeleteSharedTask`, { taskId }),
        adminGetSharedFile: (fileId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedFile`, { fileId }),
    };
}
exports.createPapoApi = createPapoApi;
