"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("@rose/types");
const colors = {
    [types_1.OnboardingStatusType.NEW]: {
        background: 'light-blue lighten-3',
        text: 'grey darken-4',
    },
    [types_1.OnboardingStatusType.PENDING]: {
        background: 'amber lighten-3',
        text: 'grey darken-4',
    },
    [types_1.OnboardingStatusType.ACCEPTED]: {
        background: 'green',
        text: 'white',
    },
    [types_1.OnboardingStatusType.ACTIVATED]: {
        background: 'indigo',
        text: 'white',
    },
    [types_1.OnboardingStatusType.IGNORE]: {
        background: 'grey lighten-1',
        text: 'grey darken-3',
    },
};
exports.default = (0, vue_1.defineComponent)({
    name: 'OnboardingMainStatusCell',
    data: () => ({
        params: {},
        colors,
    }),
    computed: {
        onboardingStatus() {
            return this.params.value;
        },
        backgroundColor() {
            var _a;
            return (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.background;
        },
        textColor() {
            var _a;
            return (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.text;
        },
    },
});
