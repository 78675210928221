"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const vue_1 = require("vue");
const stripe_1 = require("@/services/stripe");
const common_ui_1 = require("../../../../common-ui");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        // change iban dialog
        ibanValid: false,
        ibanElement: null,
        isStaging: common_ui_1.isStaging,
        isDev: common_ui_1.isDev,
    }),
    watch: {},
    async mounted() {
        this.start();
    },
    computed: {},
    methods: {
        async createPaymentMethod(customerName, customerEmail) {
            let stripe = await (0, stripe_1.getStripe)();
            let { client_secret } = await data_1.stripeApi.createPaymentIntent();
            console.log('paymentIntentId', client_secret);
            let res = await stripe.confirmSepaDebitSetup(client_secret, {
                payment_method: {
                    sepa_debit: this.ibanElement,
                    billing_details: {
                        name: customerName,
                        email: customerEmail,
                    },
                },
            });
            if (res.error) {
                throw res.error;
            }
            if (!res.setupIntent) {
                throw new Error('stripe: no setupIntent');
            }
            const payment_method = res.setupIntent.payment_method;
            if (!payment_method) {
                throw new Error('stripe: no payment method');
            }
            this.$emit('paymentMethod', payment_method);
        },
        async start() {
            let style = {
                base: {
                    fontSize: '16px',
                    color: '#32325d',
                },
            };
            let options = {
                style: style,
                supportedCountries: ['SEPA'],
                // If you know the country of the customer, you can optionally pass it to
                // the Element as placeholderCountry. The example IBAN that is being used
                // as placeholder reflects the IBAN format of that country.
                placeholderCountry: 'DE',
            };
            let stripeElements = await (0, stripe_1.getStripeElements)();
            let ibanElement = stripeElements.create('iban', options);
            ibanElement.mount('#iban-element');
            ibanElement.on('change', (event) => {
                this.ibanValid = event.complete;
                this.$emit('ibanValid', this.ibanValid);
            });
            this.ibanElement = ibanElement;
        },
    },
});
