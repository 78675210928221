"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("../../../../types");
exports.default = (0, vue_1.defineComponent)({
    name: 'TicketStatusCell',
    data: () => ({
        params: {},
        ticketStatus: types_1.FreshdeskTicketStatus,
    }),
    computed: {
        text() {
            switch (this.params.value) {
                case types_1.FreshdeskTicketStatus.Open:
                    return 'Offen';
                case types_1.FreshdeskTicketStatus.Pending:
                    return 'In Bearbeitung';
                case types_1.FreshdeskTicketStatus.Resolved:
                    return 'Gelöst';
                case types_1.FreshdeskTicketStatus.Closed:
                    return 'Geschlossen';
                case types_1.FreshdeskTicketStatus.WaitingOnCustomer:
                    return 'Warte auf Kunden';
                case types_1.FreshdeskTicketStatus.WaitingOnThirdParty:
                    return 'Warte auf Drittanbieter';
                case types_1.FreshdeskTicketStatus.CallBack:
                    return 'Rückmelden';
                case types_1.FreshdeskTicketStatus.InternalClarification:
                    return 'Interne Klärung';
                default:
                    return '';
            }
        },
        color() {
            switch (this.params.value) {
                case types_1.FreshdeskTicketStatus.Open:
                    return 'error';
                case types_1.FreshdeskTicketStatus.Pending:
                case types_1.FreshdeskTicketStatus.CallBack:
                case types_1.FreshdeskTicketStatus.InternalClarification:
                    return 'warning';
                case types_1.FreshdeskTicketStatus.Resolved:
                    return 'success';
                case types_1.FreshdeskTicketStatus.Closed:
                case types_1.FreshdeskTicketStatus.WaitingOnCustomer:
                case types_1.FreshdeskTicketStatus.WaitingOnThirdParty:
                    return 'grey';
                default:
                    return '';
            }
        },
    },
});
