"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    name: 'BotVersionCell',
    data: () => ({
        params: null,
        botVersion: null,
        isOldVersion: false,
    }),
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        this.setValues();
    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh() {
            // set value into cell again
            //   this.cellValue = this.getValueToDisplay(params);
            this.setValues();
            return true;
        },
        setValues() {
            var _a, _b, _c;
            if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
                this.botVersion = this.params.value;
                if ((_c = (_b = this.params) === null || _b === void 0 ? void 0 : _b.context) === null || _c === void 0 ? void 0 : _c.botVersion) {
                    this.isOldVersion = this.botVersion !== this.params.context.botVersion;
                }
            }
        },
        copySuccess() {
            (0, helpers_1.notifySuccess)('Copied!');
        },
        copyError(value) {
            (0, helpers_1.notifyError)('Copy failed: ' + value);
        },
    },
});
