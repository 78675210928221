"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const common_ui_1 = require("../../../../common-ui");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        rules: common_ui_1.rules,
        paymentMethod: '',
    }),
    props: {
        sponsor: { type: Object },
        createStripe: { type: Boolean, default: false },
    },
    watch: {},
    async mounted() { },
    computed: {},
    methods: {},
});
