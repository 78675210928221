"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.installRoseImageDirective = void 0;
const vue_demi_1 = require("vue-demi");
async function updateElement(el, binding, metricsApi) {
    if (binding.value === binding.oldValue) {
        return;
    }
    let imageId = binding.value;
    if (!imageId) {
        return;
    }
    // support for inlined data urls
    if (imageId.startsWith('data:')) {
        el.setAttribute('src', imageId);
        return;
    }
    let dataUri = await metricsApi.imageUpload.getImageDataUri(imageId);
    el.setAttribute('src', dataUri);
}
function installRoseImageDirective(vue, metricsApi) {
    if (vue_demi_1.isVue2) {
        vue.directive('rose-image', {
            bind(el, binding, vnode) {
                void updateElement(el, binding, metricsApi);
            },
            update(el, binding, vnode) {
                void updateElement(el, binding, metricsApi);
            },
        });
    }
    if (vue_demi_1.isVue3) {
        vue.directive('rose-image', {
            beforeMount(el, binding, vnode, prevVnode) {
                void updateElement(el, binding, metricsApi);
            },
            updated(el, binding, vnode, prevVnode) {
                void updateElement(el, binding, metricsApi);
            },
        });
    }
}
exports.installRoseImageDirective = installRoseImageDirective;
