"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.importWorkersColumns = exports.importErrorsColumns = void 0;
const gridUtils_1 = require("@/../../common-ui/src/gridUtils");
exports.importErrorsColumns = [
    {
        headerName: 'Client-ID',
        field: 'cid',
        headerTooltip: 'Eindeutige ID des Kunden',
        width: 300,
        cellRenderer: 'CopyTextCell',
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Start',
        field: 'started',
        valueFormatter: gridUtils_1.dateTimeFormatter,
        sort: 'desc',
        sortable: true,
        resizable: true,
        width: 250,
        headerTooltip: 'Startzeitpunkt des Imports',
    },
    {
        headerName: 'Dauer',
        field: 'duration',
        sortable: true,
        resizable: true,
        width: 80,
        headerTooltip: 'Dauer des Imports',
    },
    {
        headerName: 'Import-ID',
        field: 'importid',
        width: 100,
        headerTooltip: 'Eindeutige ID des Imports',
        cellRenderer: 'CopyTextCell',
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Inc',
        field: 'incremental',
        width: 80,
        cellRenderer: 'BooleanIconCell',
        headerTooltip: 'Update',
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Fehler',
        field: 'errors',
        width: 900,
        headerTooltip: 'Fehlermeldungen',
        cellRenderer: 'CopyTextCell',
        sortable: true,
        resizable: true,
        flex: 1,
    },
];
exports.importWorkersColumns = [
    {
        headerName: 'ID',
        field: 'id',
        headerTooltip: 'Eindeutige ID des Workers',
        width: 300,
        cellRenderer: 'CopyTextCell',
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Host',
        field: 'host',
        sortable: true,
        resizable: true,
        width: 250,
        cellRenderer: 'CopyTextCell',
        headerTooltip: 'Startzeitpunkt des Imports',
    },
    {
        headerName: 'PID',
        field: 'pid',
        sortable: true,
        resizable: true,
        width: 100,
        headerTooltip: 'Process ID des Workers',
    },
    {
        headerName: 'Gestarted',
        field: 'created',
        valueFormatter: gridUtils_1.dateTimeFormatter,
        sort: 'desc',
        sortable: true,
        resizable: true,
        width: 250,
        headerTooltip: 'Startzeitpunkt des Workers',
    },
    {
        headerName: 'Letzter Kontakt',
        field: 'lastContact',
        valueFormatter: gridUtils_1.dateTimeFormatter,
        sortable: true,
        resizable: true,
        width: 250,
        headerTooltip: 'Zeitpunkt des letzten Kontakts zum Worker',
    },
    {
        headerName: 'Tasks-Count',
        field: 'taskCount',
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Status',
        field: 'state',
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Task',
        field: 'task.id',
        width: 250,
        sortable: true,
        cellRenderer: 'CopyTextCell',
        resizable: true,
    },
    {
        headerName: 'Fortschritt',
        field: 'progress',
        valueFormatter: params => ((params === null || params === void 0 ? void 0 : params.value) <= 0 ? '' : params === null || params === void 0 ? void 0 : params.value),
        width: 100,
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'RSS (Memory)',
        field: 'rss',
        valueFormatter: gridUtils_1.binarySizeFormatter,
        width: 100,
        sortable: true,
        resizable: true,
    },
];
