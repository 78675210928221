"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const base_1 = require("../../../base");
console.log('versions', base_1.versions);
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        versions: base_1.versions,
    }),
});
