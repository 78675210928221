import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSolutioTeam)?_c(VCard,{staticClass:"dwh",attrs:{"outlined":""}},[_c(VCardTitle,[_c('div',{staticClass:"d-flex align-baseline"},[_c('div',{staticClass:"card-title"},[_vm._v("Profil-Gruppe & DWH")])])]),_c(VCardText,[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c(VOverlay,{attrs:{"value":_vm.isLoading,"absolute":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1):(!_vm.selectedClient.groupdata)?_c('div',{staticClass:"fetch-error"},[(_vm.hasError)?_c(VAlert,{staticClass:"mb-0",attrs:{"prominent":"","text":"","type":"error","icon":"fas fa-exclamation-triangle","dense":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Fehler beim Laden der Daten")])]):_vm._e()],1):_c('div',{staticClass:"warehouse-data"},[_c('div',{staticClass:"warehouse-data-grid"},[_c('label',{staticClass:"agb-label"},[_vm._v("AGB akzeptiert")]),(_vm.dwh)?_c('div',{staticClass:"agb-inputs"},[_c(VMenu,{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","hide-details":"","label":"am","readonly":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}],null,false,3471844953),model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c(VDatePicker,{on:{"input":function($event){_vm.showPicker = false}},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}})],1),_c(VTextField,{attrs:{"dense":"","hide-details":"","label":"durch"},model:{value:(_vm.dwh.acceptUser),callback:function ($$v) {_vm.$set(_vm.dwh, "acceptUser", $$v)},expression:"dwh.acceptUser"}}),_c(VTextField,{attrs:{"dense":"","hide-details":"","label":"für Cluster"},model:{value:(_vm.dwh.acceptedCluster),callback:function ($$v) {_vm.$set(_vm.dwh, "acceptedCluster", $$v)},expression:"dwh.acceptedCluster"}})],1):_c(VAlert,{staticClass:"mb-0",attrs:{"dense":"","text":"","type":"info"}},[_vm._v("AGB noch nicht akzeptiert")]),_c(VBtn,{staticClass:"save-button",attrs:{"block":"","x-small":"","color":"success"},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }