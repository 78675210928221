"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
const data_1 = require("@/services/data");
const stripe_1 = require("@/services/stripe");
exports.default = (0, vue_1.defineComponent)({
    name: 'ChangeIbanDialogue',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        cid: {
            type: String,
            required: true,
        },
        stripeInfoComplete: {
            type: Object,
            default: null,
        },
        isDev: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        ibanValid: false,
        ibanElement: {},
    }),
    computed: {
        showDialogue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        async showDialogue(value) {
            if (value) {
                await this.initStripeElements();
            }
        },
    },
    methods: {
        async initStripeElements() {
            await this.$nextTick(); // Ensure the DOM is updated
            let style = {
                base: {
                    fontSize: '16px',
                    color: '#32325d',
                },
            };
            let options = {
                style: style,
                supportedCountries: ['SEPA'],
                placeholderCountry: 'DE',
            };
            let stripeElements = await (0, stripe_1.getStripeElements)();
            let ibanElement = stripeElements.create('iban', options);
            // Accessing the underlying DOM element from the Vue ref
            let elementContainer = this.$refs.ibanElementContainer;
            if (elementContainer) {
                ibanElement.mount(elementContainer);
            }
            ibanElement.on('change', event => {
                this.ibanValid = event.complete;
            });
            this.ibanElement = ibanElement;
        },
        async changeIban() {
            try {
                let stripe = await (0, stripe_1.getStripe)();
                let { client_secret } = await data_1.stripeApi.createPaymentIntent();
                console.log('paymentIntentId', client_secret);
                console.log('stripe customer', this.stripeInfoComplete);
                if (!this.stripeInfoComplete) {
                    alert('no stripe info');
                    return;
                }
                if (!this.stripeInfoComplete.customer) {
                    alert('no stripe customer');
                    return;
                }
                let res = await stripe.confirmSepaDebitSetup(client_secret, {
                    payment_method: {
                        sepa_debit: this.ibanElement,
                        billing_details: {
                            name: this.stripeInfoComplete.customer.name,
                            email: this.stripeInfoComplete.customer.email,
                        },
                    },
                });
                if (res.error) {
                    throw res.error;
                }
                if (!res.setupIntent) {
                    throw new Error('stripe: no setupIntent');
                }
                const payment_method = res.setupIntent.payment_method;
                if (!payment_method) {
                    throw new Error('stripe: no payment method');
                }
                await data_1.stripeApi.changeClientIban(this.stripeInfoComplete.customer.id, payment_method);
                (0, helpers_1.notifySuccess)('Geändert');
                this.showDialogue = false;
            }
            catch (e) {
                console.log('error', e);
                (0, helpers_1.notifyError)(`Fehler! ${e.message}`);
            }
        },
    },
});
