"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const data_1 = require("@/services/data");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        gutschrifttext: '',
        rechnungstext: '',
        rewriteInvoiceDialog: false,
        // set by aggrid host
        params: null,
    }),
    methods: {
        btnClickedHandler() {
            this.rewriteInvoiceDialog = true;
        },
        async rewriteInvoice() {
            var _a, _b;
            let invoice = this.params.data;
            try {
                await data_1.stripeApi.rewriteInvoice(invoice.id, this.gutschrifttext, this.rechnungstext);
                this.rewriteInvoiceDialog = false;
            }
            catch (e) {
                console.error('rewriteInvoice fehler', e);
                alert('Fehler ' + (((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || e.message));
            }
        },
    },
});
