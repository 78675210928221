import Vue from 'vue';

import MasterTable from './components/globals/MasterTable.vue';
import KeyValueDisplay from './components/globals/KeyValueDisplay.vue';
import ChangeIban from './components/globals/ChangeIban.vue';
import ChangeIbanButton from './components/globals/ChangeIbanButton.vue';
import MasterDetailComponent from '@/components/globals/MasterDetailWrapper.vue';
import CopyText from '@/components/globals/CopyTextComponent.vue';
import SetIban from '@/components/globals/SetIban.vue';
import DashboardActionsComponent from '@/components/DashboardActionsComponent.vue';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import { AgGridVue } from 'ag-grid-vue';
import DateRangePicker from 'vue2-daterange-picker';

/**
 * Initializes all globally available components
 */
export function initGlobalComponents() {
  Vue.component('RoseDashboardActionsComponents', DashboardActionsComponent);

  Vue.component('DateRangePicker', DateRangePicker);
  Vue.component('RoseMasterTable', MasterTable);
  Vue.component('RoseKeyValueDisplay', KeyValueDisplay);
  Vue.component('RoseChangeIban', ChangeIban);
  Vue.component('RoseChangeIbanButton', ChangeIbanButton);
  Vue.component('RoseMasterDetail', MasterDetailComponent);
  Vue.component('RoseCopyText', CopyText);
  Vue.component('RoseSetIban', SetIban);

  Vue.component('AgGridVue', AgGridVue);
}
