import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.importPaused === false)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.pauseImport}},on),[_c(VIcon,[_vm._v("pause_circle_outline")])],1)]}}],null,false,1515276320)},[_c('span',[_vm._v("Update-Import pausieren")])]):_vm._e(),(_vm.importPaused === true)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.resumeImport}},on),[_c(VIcon,[_vm._v("play_circle_filled")])],1)]}}],null,false,3516501395)},[_c('span',[_vm._v("Update-Import fortsetzen")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }