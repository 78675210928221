"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readUserObject = exports.clearUserObject = exports.saveUserObject = void 0;
const lodash_1 = require("lodash");
const USER_LOCAL_STORAGE_KEY = 'user';
function saveUserObject(user) {
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify((0, lodash_1.omit)(user, 'tokens', 'clients')));
}
exports.saveUserObject = saveUserObject;
function clearUserObject() {
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}
exports.clearUserObject = clearUserObject;
function readUserObject() {
    const userSerialized = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    if (!userSerialized) {
        return null;
    }
    try {
        return JSON.parse(userSerialized);
    }
    catch (_a) {
        return null;
    }
}
exports.readUserObject = readUserObject;
