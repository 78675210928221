"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
exports.default = (0, vue_1.defineComponent)({
    props: {
        client: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        isValid: false,
        prefixLabel: 'cid',
        prefixRules: [],
        allFeatures: types_1.ALLFEATURES,
        requiredRule: (v) => !!v || 'Field is required',
    }),
    computed: {
        newClient: {
            get() {
                return this.client;
            },
            set(value) {
                this.$emit('update:client', value);
            },
        },
    },
    watch: {
        isValid() {
            this.$emit('valid', this.isValid);
        },
    },
    methods: {
        clientOnChangeLabel() {
            this.prefixLabel = this.client.addSuffix ? 'cid-Prefix' : 'cid';
            this.prefixRules = [this.requiredRule];
        },
        hasMetricsFeature() {
            return (0, lodash_1.includes)(this.newClient.features, types_1.FEATURES.METRICS);
        },
    },
});
