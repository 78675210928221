import { render, staticRenderFns } from "./KostenfreiCell.vue?vue&type=template&id=3276e332&scoped=true&lang=pug&"
import script from "./KostenfreiCell.vue?vue&type=script&lang=ts&"
export * from "./KostenfreiCell.vue?vue&type=script&lang=ts&"
import style0 from "./KostenfreiCell.vue?vue&type=style&index=0&id=3276e332&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3276e332",
  null
  
)

export default component.exports