"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWorkBook = exports.saveXLSXFromBase64 = exports.saveXLSX = void 0;
const file_saver_1 = require("file-saver");
const base_1 = require("../../../base");
const ExcelJS = __importStar(require("exceljs"));
function saveXLSX(workbook, name) {
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        (0, file_saver_1.saveAs)(blob, name);
    });
}
exports.saveXLSX = saveXLSX;
async function saveXLSXFromBase64(base64, name) {
    let xlsx = await (0, base_1.getXLSXFromBuffer)(createWorkBook(), atob(base64));
    saveXLSX(xlsx, name);
}
exports.saveXLSXFromBase64 = saveXLSXFromBase64;
function createWorkBook() {
    return new ExcelJS.Workbook();
}
exports.createWorkBook = createWorkBook;
