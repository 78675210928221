"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        params: null,
    }),
    methods: {
        async remailInvoice() {
            var _a, _b;
            let invoice = this.params.data;
            try {
                await data_1.stripeApi.remailInvoice(invoice.id);
                (0, helpers_1.notifySuccess)('Mail versendet');
            }
            catch (e) {
                console.error('remailinvoice fehler', e);
                (0, helpers_1.notifyError)(`Fehler beim versenden: ${((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || e.message}`);
            }
        },
    },
});
