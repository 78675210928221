import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c(VBtn,{attrs:{"color":"primary","x-small":""},on:{"click":_vm.btnClickedHandler}},[_vm._v("Neuschreiben")]),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.rewriteInvoiceDialog),callback:function ($$v) {_vm.rewriteInvoiceDialog=$$v},expression:"rewriteInvoiceDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Rechnung neuschreiben")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Gutschrifttext"},model:{value:(_vm.gutschrifttext),callback:function ($$v) {_vm.gutschrifttext=$$v},expression:"gutschrifttext"}}),_c(VTextField,{attrs:{"label":"Rechnungstext"},model:{value:(_vm.rechnungstext),callback:function ($$v) {_vm.rechnungstext=$$v},expression:"rechnungstext"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.rewriteInvoiceDialog = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.rewriteInvoice.apply(null, arguments)}}},[_vm._v("Ändern")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }