import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"card elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Admin Login")]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","large":"","href":_vm.source,"target":"_blank"}},on),[_c(VIcon,{attrs:{"large":""}},[_vm._v("help_outline")])],1)]}}])},[_c('span',[_vm._v("help")])])],1),(_vm.loginMailSent)?_c(VCardText,{on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c('div',{staticClass:"mt-8"}),_c(VAlert,{attrs:{"type":"success"}},[_vm._v("Wir haben einen Link zum Login an deine E-Mail-Adresse gesendet.")]),_c('div',{staticClass:"mt-8"})],1):_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c(VCardText,[_c(VTextField,{attrs:{"prepend-icon":"person","name":"login","label":"Login","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"id":"password","prepend-icon":"lock","name":"password","label":"Password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.showAuthenticatorCode),expression:"showAuthenticatorCode"}],attrs:{"prepend-icon":"mobile_friendly","name":"authenticatorCode","label":"Authenticator Code","type":"text"},model:{value:(_vm.mfaCode),callback:function ($$v) {_vm.mfaCode=$$v},expression:"mfaCode"}}),(_vm.loginErrorString)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.loginErrorString))]):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"px-8",attrs:{"color":"primary","type":"submit"}},[_vm._v("Login")])],1)],1),(_vm.loginPending)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }