"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const _ = __importStar(require("lodash"));
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    name: 'CopyTextCell',
    data: () => ({
        params: null,
        displayValue: null,
        copyValue: null,
    }),
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        this.setValues();
    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh(params) {
            // set value into cell again
            //   this.cellValue = this.getValueToDisplay(params);
            this.setValues();
            return true;
        },
        setValues() {
            var _a;
            if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
                if (_.isArray(this.params.value)) {
                    this.displayValue = _.join(this.params.value);
                }
                else {
                    this.displayValue = this.params.value;
                }
                this.copyValue = String(this.params.value);
            }
        },
        copySuccess() {
            (0, helpers_1.notifySuccess)('Copied!');
        },
        copyError(value) {
            (0, helpers_1.notifyError)('Copy failed: ' + this.displayValue);
        },
    },
});
