"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigTerminzeiten = void 0;
const types_1 = require("../../../types");
const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
function benchmarkConfigTerminzeiten() {
    const dauerStatsChildsRaw = [30, 60, 90, 120, 150]
        .map(l => ({
        l,
        c: `dauer${l}Count`,
        d: `dauer${l}Dauer`,
        n: `Terminlänge [${l - 29}-${l}min]`,
    }))
        .map(f => ({
        x: `_td${f.l}`,
        name: f.n,
        isHeader: true,
        childs: {
            [`_${f.c}`]: {
                name: 'Anzahl',
                value: `b.tzDauerStats?.${f.c}`,
                virtual: true,
                trendType: types_1.IBenchmarkConfigTrendType.none,
                smartValue: 's / b.tzDauerStats?.dauerAllCount * 100',
                smartUnit: '%',
                unit: 'T',
                noAvg: true,
            },
            [`_${f.d}`]: {
                name: 'Gesamtdauer',
                value: `b.tzDauerStats?.${f.d}`,
                virtual: true,
                trendType: types_1.IBenchmarkConfigTrendType.none,
                smartValue: 's / b.tzDauerStats?.dauerAllDauer * 100',
                smartUnit: '%',
                noAvg: true,
            },
        },
    }));
    const dauerStatsChilds = keyBy(dauerStatsChildsRaw, 'x');
    return {
        name: 'Terminzeiten',
        unit: 'min',
        isHeader: true,
        childs: {
            tzAvgTermin: {
                name: '⌀ Termindauer (Vorgabe)',
                trendType: types_1.IBenchmarkConfigTrendType.none,
                noAvg: true,
            },
            tzMedianTermin: {
                name: 'Median Termindauer (Vorgabe)',
                trendType: types_1.IBenchmarkConfigTrendType.none,
                noAvg: true,
            },
            tzAvgWartezimmer: {
                name: '⌀ Wartezeit Wartezimmer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            tzMedianWartezimmer: {
                name: 'Median Wartezeit Wartezimmer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            tzAvgBehandlungszimmer: {
                name: '⌀ Wartezeit Behandlungszimmer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
                excludeForPVS: ['z1', 'z1v2'],
            },
            tzMedianBehandlungszimmer: {
                name: 'Median Wartezeit Behandlungszimmer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
                excludeForPVS: ['z1', 'z1v2'],
            },
            tzAvgBehandlung: {
                name: '⌀ Behandlungsdauer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            tzMedianBehandlung: {
                name: 'Median Behandlungsdauer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            tzAvgDiffPlanRealStart: {
                name: '⌀ Abweichung zwischen tatsächlicher und geplanter Termindauer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            tzMedianDiffPlanRealStart: {
                name: 'Median Abweichung zwischen tatsächlicher und geplanter Termindauer',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                noAvg: true,
            },
            _tzDauerStats: {
                name: 'Termindauer-Statistik',
                isHeader: true,
                unit: 'h:m',
                collapsed: false,
                childs: {
                    dauerAllCount: {
                        name: 'Anzahl',
                        value: `b.tzDauerStats?.dauerAllCount`,
                        virtual: true,
                        trendType: types_1.IBenchmarkConfigTrendType.none,
                        smartValue: '100',
                        smartUnit: '%',
                        unit: 'T',
                        noAvg: true,
                    },
                    dauerAllDauer: {
                        name: 'Gesamtdauer',
                        value: `b.tzDauerStats?.dauerAllDauer`,
                        virtual: true,
                        trendType: types_1.IBenchmarkConfigTrendType.none,
                        smartValue: '100',
                        smartUnit: '%',
                        noAvg: true,
                    },
                    ...dauerStatsChilds,
                    _dauerMax: {
                        name: 'Terminlänge [151min - ∞]',
                        isHeader: true,
                        childs: {
                            dauerMaxCount: {
                                name: 'Anzahl',
                                value: `b.tzDauerStats?.dauerMaxCount`,
                                virtual: true,
                                trendType: types_1.IBenchmarkConfigTrendType.none,
                                smartValue: '100',
                                smartUnit: '%',
                                unit: 'T',
                                noAvg: true,
                            },
                            dauerMaxDauer: {
                                name: 'Gesamtdauer',
                                value: `b.tzDauerStats?.dauerMaxDauer`,
                                virtual: true,
                                trendType: types_1.IBenchmarkConfigTrendType.none,
                                smartValue: '100',
                                smartUnit: '%',
                                noAvg: true,
                            },
                        },
                    },
                },
            },
        },
    };
}
exports.benchmarkConfigTerminzeiten = benchmarkConfigTerminzeiten;
