"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userAdminLevelOptions = void 0;
const types_1 = require("../../../types");
exports.userAdminLevelOptions = [
    { text: 'Normaler Benutzer', value: types_1.ADMIN_LEVEL.NONE },
    { text: 'Admin ohne Benutzerverwaltung', value: types_1.ADMIN_LEVEL.PRAXISMANAGER },
    { text: 'Admin mit Benutzerverwaltung', value: types_1.ADMIN_LEVEL.CLIENT_ADMIN },
    { text: 'rose-Team', value: types_1.ADMIN_LEVEL.ROSE_TEAM },
    { text: 'solutio-Team', value: types_1.ADMIN_LEVEL.SOLUTIO_TEAM },
];
