"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("@rose/types");
const colors = {
    [types_1.OnboardingProcessStatusType.UNBEARBEITET]: {
        background: 'grey lighten-2',
        text: 'grey darken-2',
    },
    [types_1.OnboardingProcessStatusType.VERSANDT]: {
        background: 'blue',
        text: 'white',
    },
    [types_1.OnboardingProcessStatusType.ANGEKOMMEN]: {
        background: 'yellow darken-1',
        text: 'grey darken-2',
    },
    [types_1.OnboardingProcessStatusType.VERBINDUNG_OK]: {
        background: 'green',
        text: 'white',
    },
    [types_1.OnboardingProcessStatusType.ERSTSCHULUNG]: {
        background: 'purple',
        text: 'white',
    },
};
exports.default = (0, vue_1.defineComponent)({
    name: 'OnboardingProcessStatusCell',
    data: () => ({
        params: {},
        colors,
    }),
    computed: {
        onboardingStatus() {
            return this.params.value;
        },
        backgroundColor() {
            var _a;
            return (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.background;
        },
        textColor() {
            var _a;
            return (_a = colors[this.onboardingStatus]) === null || _a === void 0 ? void 0 : _a.text;
        },
    },
});
