"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const data_1 = require("@/services/data");
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
const common_ui_1 = require("@rose/common-ui");
exports.default = (0, vue_1.defineComponent)({
    name: 'ClientHelpdesk',
    props: {
        isSolutioTeam: Boolean,
        selectedClient: { type: Object, default: null },
    },
    data() {
        return {
            isLoading: false,
            isLinking: false,
            hasError: false,
            error: {},
            company: null,
            contacts: [],
            showHelpdeskIdModal: false,
            customHelpdeskId: '',
            gridOptions: {
                rowHeight: 80,
            },
            columnsContacts: [
                {
                    headerName: 'Create Ticket',
                    field: 'newticketlink',
                    sortable: true,
                    width: 50,
                    resizable: true,
                    cellRenderer: 'LinkCellComponent',
                    cellRendererParams: {
                        linkText: 'new',
                        linkIcon: 'fa-ticket',
                    },
                },
                { headerName: 'Kontakt-ID', field: 'id', sortable: true, width: 110, resizable: true },
                { headerName: 'Name', field: 'name', sortable: true, width: 160, resizable: true },
                {
                    headerName: 'Funktion',
                    field: 'job_title',
                    sortable: true,
                    width: 125,
                    resizable: true,
                    cellRenderer: 'ContactPositionCell',
                },
                { headerName: 'Email', field: 'email', sortable: true, width: 180, resizable: true },
                { headerName: 'Telefon', field: 'phone', sortable: true, width: 150, resizable: true },
                { headerName: 'Mobile', field: 'mobile', sortable: true, width: 150, resizable: true },
            ],
            tickets: [],
            columnsTickets: [
                { headerName: 'Ticket-ID', field: 'id', sortable: true, width: 80, resizable: true },
                { headerName: 'Kontakt', field: 'name', sortable: true, width: 160, resizable: true },
                { headerName: 'Betreff', field: 'subject', sortable: true, width: 250, resizable: true },
                {
                    headerName: 'Status',
                    field: 'status',
                    sortable: true,
                    width: 110,
                    resizable: true,
                    cellRenderer: 'TicketStatusCell',
                    cellStyle: { display: 'flex', 'justify-content': 'start', 'align-items': 'center' },
                },
                {
                    headerName: 'Typ',
                    field: 'type',
                    sortable: true,
                    width: 80,
                    resizable: true,
                },
                {
                    headerName: 'Erstellt',
                    field: 'created_at',
                    sortable: true,
                    width: 130,
                    valueFormatter: common_ui_1.dateTimeFormatter,
                    resizable: true,
                },
                {
                    headerName: 'Zuletzt bearbeitet',
                    field: 'updated_at',
                    sortable: true,
                    width: 160,
                    resizable: true,
                    valueFormatter: common_ui_1.dateTimeFormatter,
                },
            ],
        };
    },
    computed: {
        helpdeskid() {
            var _a;
            return (_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.helpdeskid;
        },
        helpdeskLink() {
            var _a;
            return `https://rose.freshdesk.com/a/companies/${(_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.helpdeskid}`;
        },
        errorText() {
            var _a, _b;
            return (_b = (_a = this.error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message;
        },
        canSync() {
            var _a, _b;
            return ((_a = this.company) === null || _a === void 0 ? void 0 : _a.name) !== ((_b = this.selectedClient) === null || _b === void 0 ? void 0 : _b.praxisKurzName);
        },
    },
    watch: {
        selectedClient: {
            async handler(oldValue, newValue) {
                if ((oldValue === null || oldValue === void 0 ? void 0 : oldValue.cid) !== (newValue === null || newValue === void 0 ? void 0 : newValue.cid)) {
                    await this.init();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    async beforeMount() {
        await this.init();
        console.log('data', this.contacts, this.tickets);
    },
    methods: {
        async init() {
            try {
                console.log('init helpdesk');
                this.isLoading = true;
                this.hasError = false;
                this.customHelpdeskId = '';
                if (this.helpdeskid) {
                    this.company = await data_1.metricsApi.helpdesk.getCompany(+this.helpdeskid);
                    const contactsRaw = await data_1.metricsApi.helpdesk.getContacts(+this.helpdeskid);
                    console.log(contactsRaw);
                    this.contacts = (0, lodash_1.map)(contactsRaw, c => (Object.assign(Object.assign({}, c), { contactLink: `https://rose.freshdesk.com/a/contacts/${c.id}`, newticketlink: `https://rose.freshdesk.com/a/tickets/new?contactId=${c.id}` })));
                    const ticketsRaw = await data_1.metricsApi.helpdesk.getTickets(+this.helpdeskid);
                    const contactsMap = (0, lodash_1.keyBy)(this.contacts, 'id');
                    this.tickets = (0, lodash_1.map)(ticketsRaw, (ticket) => {
                        var _a;
                        return (Object.assign(Object.assign({}, ticket), { name: (ticket.requester_id && ((_a = contactsMap[ticket.requester_id]) === null || _a === void 0 ? void 0 : _a.name)) || '-', link: `https://rose.freshdesk.com/a/tickets/${ticket.id}` }));
                    });
                }
            }
            catch (e) {
                console.error(e);
                this.hasError = true;
                this.error = e;
            }
            finally {
                this.isLoading = false;
            }
        },
        async createCompany() {
            var _a;
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => { var _a; return (0, data_1.createHelpdeskInfo)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid); },
                successMessage: `Helpdesk-Kunde für ${(_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid} wurde erstellt`,
                successCallback: async () => {
                    this.$emit('reload');
                },
            });
        },
        async setHelpdeskId() {
            var _a;
            this.isLoading = true;
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => { var _a; return (0, data_1.setHelpdeskId)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, this.customHelpdeskId); },
                successMessage: `Helpdesk-ID für ${(_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid} wurde aktualisiert`,
                successCallback: async () => {
                    this.$emit('reload');
                },
            });
        },
        onTicketCellClicked(params) {
            window.open(params.data.link, '_blank');
        },
        onContactCellClicked(params) {
            window.open(params.data.contactLink, '_blank');
        },
        showHelpdeskIdModalDialog() {
            this.showHelpdeskIdModal = true;
        },
        sync() {
            var _a, _b;
            if (!this.company) {
                return;
            }
            const company = Object.assign(Object.assign({}, this.company), { name: (_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.praxisKurzName });
            void (0, helpers_1.handleApiCall)({
                apiFunction: () => data_1.metricsApi.helpdesk.updateCompany(company),
                successMessage: `Helpdesk-Kunde für ${(_b = this.selectedClient) === null || _b === void 0 ? void 0 : _b.cid} wurde aktualisiert`,
                successCallback: async () => {
                    this.$emit('reload');
                },
            });
        },
    },
});
