"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'BooleanIconCell',
    data: () => ({
        params: null,
        isTrue: null,
    }),
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        this.setValues();
    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh(params) {
            // set value into cell again
            //   this.cellValue = this.getValueToDisplay(params);
            this.setValues();
            return true;
        },
        setValues() {
            var _a;
            if (typeof ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) !== 'undefined') {
                this.isTrue = this.params.value;
            }
        },
    },
});
