"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigBehandlungen = void 0;
const benchmark_config_rezepteHmvAuKt_1 = require("./benchmark.config.rezepteHmvAuKt");
function u(s) {
    return s ? s[0].toLocaleUpperCase() + s.substring(1) : '';
}
const flaechigkeit = [
    { key: '1fl', name: '1-flächig' },
    { key: '2fl', name: '2-flächig' },
    { key: '3fl', name: '3-flächig' },
    { key: '4fl', name: '4-flächig' },
];
const leistungen = {
    gkv: [
        {
            key: 'MKF',
            name: 'Mehrkosten-Füllungen (mit Zuzahlung)',
            anzahl: true,
            help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13a-d, mit fiktiver Verrechnung und korrespondierender GOZ Leistung.',
        },
        {
            key: 'MKF',
            name: 'Mehrkosten-Füllungen (mit Zuzahlung)',
            durchschnitt: true,
            help: 'Durchschnittlicher Betrag der Füllungsleistungen bei GKV-Patienten nach den Nummern GOZ 2060, 2080, 2100, 2120 abzüglich der jeweiligen fiktiven BEMA Verrechnung.',
        },
        {
            key: 'kassenfuellung',
            name: 'Kassenfüllungen (ohne Zuzahlung)',
            children: [
                {
                    key: 'dentinadhaesiv',
                    name: 'Dentinadhäsiv',
                    anzahl: true,
                    help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13e-h (Bei Kindern bis 14 Jahre und Amalgam Kontraindikation).',
                },
                {
                    key: 'komposit',
                    name: 'Komposit',
                    anzahl: true,
                    help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13a-d.',
                },
                {
                    key: 'amalgam',
                    name: 'Amalgam',
                    anzahl: true,
                    help: 'Anzahl der Füllungsleistungen bei GKV-Patienten nach den Nummern BEMA 13aA-dA mit der Kennzeichnung Amalgam (A).',
                },
            ],
        },
    ],
    pkv: [
        {
            key: 'komposit',
            name: 'Komposit',
            anzahl: true,
            help: 'Anzahl der Füllungsleistungen bei PKV-Patienten nach den Nummern GOZ 2060, 2080, 2100, 2120.',
        },
        {
            key: 'zement',
            name: 'Zement',
            anzahl: true,
            help: 'Anzahl der Füllungsleistungen bei PKV-Patienten nach den Nummern GOZ 2050, 2070, 2090, 2110.',
        },
    ],
};
function createFuellungenFlaechigkeitChilds() {
    const flaechigkeitChilds = {};
    for (const f of flaechigkeit) {
        flaechigkeitChilds[`_${f.key}`] = {
            name: f.name,
            isHeader: true,
            value: `b.behFlg${f.key}Anzahl + b.behFlg${f.key}GkvMKFAnzahl`,
            unit: 'F',
            smartValue: `b.behFlg${f.key}Anzahl / b.behFlgAnzahl * 100`,
            smartUnit: '%',
            virtual: true,
            childs: createFuellungenVersicherungsartChilds(f.key),
            collapsed: true,
            noHistory: true,
        };
    }
    return flaechigkeitChilds;
}
function createFuellungenVersicherungsartChilds(fkey, mitGesamt = true) {
    const vChilds = {};
    const gkv = { key: 'gkv', name: 'Bei GKV' };
    const g = `b.behFlg${u(fkey)}${u(gkv.key)}`;
    const pkv = { key: 'pkv', name: 'Bei PKV' };
    const p = `b.behFlg${u(fkey)}${u(pkv.key)}`;
    if (mitGesamt) {
        vChilds[`_behFlg${u(fkey)}Anzahl`] = {
            name: 'Insgesamt',
            unit: 'F',
            virtual: true,
            value: `b.behFlg${u(fkey)}Anzahl + b.behFlg${u(fkey)}GkvMKFAnzahl`,
            smartValue: `s / b.behFlgAnzahl * 100`,
            smartUnit: '%',
            help: 'Anzahl der Füllungsleistungen nach den Nummern BEMA 13a-h und GOZ 2050-2120 (Abrechnungsleistungen je nach Fläche).',
            noHistory: true,
        };
    }
    // bei GKV
    vChilds[`_behFlg${u(fkey)}${u(gkv.key)}Anzahl`] = {
        name: gkv.name,
        unit: 'F',
        virtual: true,
        value: `${g}MKFAnzahl + ${g}AmalgamAnzahl + ${g}DentinadhaesivAnzahl + ${g}KompositAnzahl`,
        smartValue: `s / b.behFlg${u(fkey)}Anzahl * 100`,
        smartUnit: '%',
        childs: createLChilds(fkey, gkv.key),
        noHistory: true,
    };
    // bei PKV
    vChilds[`_behFlg${u(fkey)}${u(pkv.key)}Anzahl`] = {
        name: pkv.name,
        unit: 'F',
        virtual: true,
        value: `${p}ZementAnzahl + ${p}KompositAnzahl`,
        smartValue: `s / b.behFlg${u(fkey)}Anzahl * 100`,
        smartUnit: '%',
        childs: createLChilds(fkey, pkv.key),
        noHistory: true,
    };
    return vChilds;
}
function createLChilds(fkey, vkey) {
    const lChilds = {};
    for (const l of leistungen[vkey]) {
        if (!l.privatSpezial) {
            if (l.anzahl) {
                lChilds[`behFlg${u(fkey)}${u(vkey)}${u(l.key)}Anzahl`] = {
                    name: l.name,
                    unit: 'F',
                    smartValue: `s / b.behFlg${u(fkey)}Anzahl * 100`,
                    smartUnit: '%',
                    help: l.help,
                    noHistory: true,
                };
            }
            if (l.betrag) {
                lChilds[`behFlg${u(fkey)}${u(vkey)}${u(l.key)}Betrag`] = {
                    name: `${l.name} €`,
                    unit: 'eur',
                    smartValue: `s / b.behFlg${u(fkey)}Betrag * 100`,
                    smartUnit: '%',
                    help: l.help,
                    noHistory: true,
                };
            }
            if (l.durchschnitt) {
                lChilds[`_behFlg${u(fkey)}${u(vkey)}${u(l.key)}Durchschnitt`] = {
                    name: `${l.name} ø€`,
                    virtual: true,
                    value: `Math.abs(b.behFlg${u(fkey)}${u(vkey)}${u(l.key)}Betrag) / b.behFlg${u(fkey)}${u(vkey)}${u(l.key)}Anzahl`,
                    unit: 'eur',
                    help: l.help,
                    noHistory: true,
                };
            }
            if (l.children) {
                const key = `_behFlg${u(fkey)}${u(vkey)}${u(l.key)}`;
                lChilds[key] = {
                    name: l.name,
                    unit: 'F',
                    smartValue: `s / b.behFlg${u(fkey)}Anzahl  * 100`,
                    smartUnit: '%',
                    value: `b.behFlg${u(fkey)}${u(vkey)}KompositAnzahl + b.behFlg${u(fkey)}${u(vkey)}AmalgamAnzahl + b.behFlg${u(fkey)}${u(vkey)}DentinadhaesivAnzahl`,
                    virtual: true,
                    isHeader: true,
                    collapsed: true,
                    childs: {},
                    noHistory: true,
                };
                for (const c of l.children) {
                    const childs = lChilds[key].childs;
                    if (childs) {
                        childs[`behFlg${u(fkey)}${u(vkey)}${u(c.key)}Anzahl`] = {
                            name: c.name,
                            unit: 'F',
                            smartValue: `s / (${lChilds[key].value}) * 100`,
                            smartUnit: '%',
                            help: c.help,
                            noHistory: true,
                        };
                    }
                }
            }
        }
        else {
            lChilds[`_behFlg${u(fkey)}${u(vkey)}${u(l.key)}Anzahl`] = {
                name: `${l.name}`,
                virtual: true,
                value: `b.behFlg${u(fkey)}${u(vkey)}${u(l.key)}Anzahl - b.behFlg${u(fkey)}${u(vkey)}MKFAnzahl`,
                unit: 'F',
                smartValue: `s / b.behFlg${u(fkey)}Anzahl * 100`,
                smartUnit: '%',
                help: l.help,
                noHistory: true,
            };
        }
    }
    return lChilds;
}
function createImplChilds(implantatLeistungen) {
    const ret = {
        behImplaGesamtAnzahl: {
            name: 'Anzahl gesetzte Implantate',
            unit: 'I',
            noHistory: true,
        },
        behImplaGesamtBetrag: {
            name: 'Honorar gesetzte Implantate',
            unit: 'eur',
            noHistory: true,
        },
        behImplaGesamtPatienten: {
            name: 'Patienten mit gesetztem Implantat',
            unit: 'P',
            noHistory: true,
        },
        _behImplaGesamtImplantateJePatient: {
            name: 'Implantate je Patient mit Implantat',
            unit: 'I',
            formatHint: '0.0',
            virtual: true,
            value: `b.behImplaGesamtAnzahl / b.behImplaGesamtPatienten`,
            noHistory: true,
        },
        _behImplaGesamtHonorarJePatient: {
            name: 'Honorar je Patient mit Implantat',
            unit: 'eur',
            virtual: true,
            value: `b.behImplaGesamtBetrag / b.behImplaGesamtPatienten`,
            noHistory: true,
        },
        _behImplaGesamtHonorarJeImplantat: {
            name: 'Honorar je Implantat',
            unit: 'eur',
            virtual: true,
            value: `b.behImplaGesamtBetrag / b.behImplaGesamtAnzahl`,
            noHistory: true,
        },
    };
    for (const impla of implantatLeistungen) {
        ret[`_behImpla${impla}`] = {
            name: impla,
            value: `b.behImpla['${impla}'].gesamtBetrag`,
            virtual: true,
            smartValue: `s / b.behImplaGesamtBetrag * 100`,
            smartUnit: '%',
            noHistory: true,
            childs: {
                [`_behImpla${impla}GesamtAnzahl`]: {
                    name: 'Anzahl gesetzte Implantate',
                    value: `b.behImpla['${impla}'].gesamtAnzahl`,
                    virtual: true,
                    unit: 'I',
                    noHistory: true,
                },
                [`_behImpla${impla}GesamtBetrag`]: {
                    name: 'Honorar gesetzte Implantate',
                    value: `b.behImpla['${impla}'].gesamtBetrag`,
                    virtual: true,
                    unit: 'eur',
                    noHistory: true,
                },
                [`_behImpla${impla}GesamtPatienten`]: {
                    name: 'Patienten mit gesetztem Implantat',
                    value: `b.behImpla['${impla}'].gesamtPatienten`,
                    virtual: true,
                    unit: 'P',
                    noHistory: true,
                },
                [`_behImpla${impla}Kasse`]: {
                    name: 'Bei GKV',
                    isHeader: true,
                    noHistory: true,
                    childs: {
                        [`_behImpla${impla}KasseAnzahl`]: {
                            name: 'Anzahl gesetzte Implantate',
                            value: `b.behImpla['${impla}'].kasseAnzahl`,
                            virtual: true,
                            unit: 'I',
                            noHistory: true,
                        },
                        [`_behImpla${impla}KasseBetrag`]: {
                            name: 'Honorar gesetzte Implantate',
                            value: `b.behImpla['${impla}'].kasseBetrag`,
                            virtual: true,
                            unit: 'eur',
                            noHistory: true,
                        },
                        [`_behImpla${impla}KassePatienten`]: {
                            name: 'Patienten mit gesetztem Implantat',
                            value: `b.behImpla['${impla}'].kassePatienten`,
                            virtual: true,
                            unit: 'P',
                            noHistory: true,
                        },
                    },
                },
                [`_behImpla${impla}Privat`]: {
                    name: 'Bei PKV',
                    isHeader: true,
                    noHistory: true,
                    childs: {
                        [`_behImpla${impla}PrivatAnzahl`]: {
                            name: 'Anzahl gesetzte Implantate',
                            value: `b.behImpla['${impla}'].privatAnzahl`,
                            virtual: true,
                            unit: 'I',
                            noHistory: true,
                        },
                        [`_behImpla${impla}PrivatBetrag`]: {
                            name: 'Honorar gesetzte Implantate',
                            value: `b.behImpla['${impla}'].privatBetrag`,
                            virtual: true,
                            unit: 'eur',
                            noHistory: true,
                        },
                        [`_behImpla${impla}PrivatPatienten`]: {
                            name: 'Patienten mit gesetztem Implantat',
                            value: `b.behImpla['${impla}'].privatPatienten`,
                            virtual: true,
                            unit: 'P',
                            noHistory: true,
                        },
                    },
                },
            },
        };
    }
    return ret;
}
function benchmarkConfigBehandlungen(implantatLeistungen) {
    return {
        name: 'Behandlungen',
        unit: 'eur',
        help: 'Im ausgewählten Zeitraum erbrachte Behandlungen.',
        smartValue: '100',
        smartUnit: '%',
        value: 'b.behandlungenAnzahl',
        isHeader: true,
        virtual: true,
        noHistory: true,
        childs: {
            _konsBehandlungen: {
                name: 'Konservierende Behandlungen',
                isHeader: true,
                value: 'b.behandlungenAnzahl',
                noHistory: true,
                childs: {
                    _fuellungenBehandlungen: {
                        name: 'Füllungstherapie',
                        isHeader: true,
                        virtual: true,
                        value: 'b.behFlgGesamtAnzahl',
                        noHistory: true,
                        childs: {
                            behFlgGesamtAnzahl: {
                                name: 'Anzahl Füllungen insgesamt',
                                unit: 'F',
                                help: 'Zur Ermittlung der Anzahl der gelegten Füllungen im Betrachtungszeitraum werden die erbrachten Leistungen nach den Nummern BEMA 13a-h und GOZ 2050-2120 ausgewertet.',
                                noHistory: true,
                            },
                            behFlgGesamtBetrag: {
                                name: 'Honorar Füllungen insgesamt',
                                unit: 'eur',
                                help: 'Das erwirtschaftete Honorar der gelegten Füllungen bezieht sich lediglich auf die eigentlich erbrachten Füllungsleistungen BEMA 13a-h und GOZ 2050-2120, ohne Begleitleistungen.',
                                noHistory: true,
                            },
                            // behandlungenTermineAnzahl: {
                            //   name: 'Termine mit Füllungen',
                            //   unit: 'T',
                            // },
                            behFlgPatientenAnzahl: {
                                name: 'Patienten mit Füllungen',
                                unit: 'P',
                                help: 'Anzahl der Patienten, bei denen Füllungsleistungen im Betrachtungszeitraum erbracht wurden.',
                                smartValue: 'b.behFlgPatientenAnzahl / b.patienten * 100',
                                smartUnit: '%',
                                noHistory: true,
                            },
                            // _fuellungenJeTermin: {
                            //   name: 'Füllungen je Termin mit Füllung',
                            //   virtual: true,
                            //   value: 'b.behFlgGesamtAnzahl / b.behandlungenTermineAnzahl',
                            //   unit: 'F',
                            // },
                            _fuellungenJePatient: {
                                name: 'Füllungen je Patient',
                                virtual: true,
                                value: 'b.behFlgGesamtAnzahl / b.behFlgPatientenAnzahl',
                                unit: 'F/P',
                                formatHint: '0,0.0',
                                help: 'Anzahl der gelegten Füllungen, je behandelten Patient mit Füllungsleistungen, im Betrachtungszeitraum.',
                                noHistory: true,
                            },
                            ...createFuellungenVersicherungsartChilds('Gesamt', false),
                            ...createFuellungenFlaechigkeitChilds(),
                        },
                    },
                },
            },
            _implaBehandlungen: {
                name: 'Implantate',
                isHeader: true,
                virtual: true,
                value: 'b.behImplaGesamtAnzahl',
                help: 'Welche Implantatsleistungen hier gezählt werden, kann in den Einstellungen unter Leistungen konfiguriert werden',
                childs: createImplChilds(implantatLeistungen),
                noHistory: true,
            },
            _rezeptHmvAuKt: (0, benchmark_config_rezepteHmvAuKt_1.benchmarkConfigRezepteHmvAuKt)(),
        },
    };
}
exports.benchmarkConfigBehandlungen = benchmarkConfigBehandlungen;
