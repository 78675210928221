import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Neue Lizenz anlegen")]),_c(VCardText,[_c(VForm,[_c('CaLicenceFields',{attrs:{"licence":_vm.licence}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = false}}},[_vm._v("Abbrechen")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.licence && !_vm.licence.serialnumber,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.create.apply(null, arguments)}}},[_vm._v("Anlegen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }