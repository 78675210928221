import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{staticClass:"wrap",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"status-table"},[_c('div',{staticClass:"von-date-container"},[_c('span',{staticClass:"pr-3"},[_vm._v("Start:")]),_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({staticClass:"mr-6",attrs:{"value":_vm.vonDateFormatted,"label":"Datum","persistent-hint":"","prepend-icon":"event","rules":[_vm.requiredRule]},on:{"click:clear":function($event){_vm.vonDate = null}}},on))]}}]),model:{value:(_vm.vonDateMenu),callback:function ($$v) {_vm.vonDateMenu=$$v},expression:"vonDateMenu"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.vonDateMenu = false}},model:{value:(_vm.vonDate),callback:function ($$v) {_vm.vonDate=$$v},expression:"vonDate"}})],1),_c(VMenu,{ref:"vonMenuRef",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.vonTime,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){_vm.vonTime=$event},"update:return-value":function($event){_vm.vonTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Zeit","prepend-icon":"access_time","readonly":""},model:{value:(_vm.vonTime),callback:function ($$v) {_vm.vonTime=$$v},expression:"vonTime"}},on))]}}]),model:{value:(_vm.vonTimeMenu),callback:function ($$v) {_vm.vonTimeMenu=$$v},expression:"vonTimeMenu"}},[(_vm.vonTimeMenu)?_c(VTimePicker,{attrs:{"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.vonMenuRef.save(_vm.vonTime)}},model:{value:(_vm.vonTime),callback:function ($$v) {_vm.vonTime=$$v},expression:"vonTime"}}):_vm._e()],1)],1),_c('div',{staticClass:"bis-date-container"},[_c(VCheckbox,{attrs:{"label":"Ende bekannt?"},model:{value:(_vm.withEndTime),callback:function ($$v) {_vm.withEndTime=$$v},expression:"withEndTime"}}),(_vm.withEndTime)?_c('span',{staticClass:"pa-3"},[_vm._v("Ende:")]):_vm._e(),(_vm.withEndTime)?_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({staticClass:"mr-6",attrs:{"value":_vm.bisDateFormatted,"label":"Datum","disabled":!_vm.withEndTime,"persistent-hint":"","prepend-icon":"event"},on:{"click:clear":function($event){_vm.bisDate = null}}},on))]}}],null,false,3316351040),model:{value:(_vm.bisDateMenu),callback:function ($$v) {_vm.bisDateMenu=$$v},expression:"bisDateMenu"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.bisDateMenu = false}},model:{value:(_vm.bisDate),callback:function ($$v) {_vm.bisDate=$$v},expression:"bisDate"}})],1):_vm._e(),(_vm.withEndTime)?_c(VMenu,{ref:"bisMenuRef",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.bisTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.bisTime=$event},"update:return-value":function($event){_vm.bisTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Zeit","disabled":!_vm.withEndTime,"prepend-icon":"access_time","readonly":""},model:{value:(_vm.bisTime),callback:function ($$v) {_vm.bisTime=$$v},expression:"bisTime"}},on))]}}],null,false,2367152159),model:{value:(_vm.bisTimeMenu),callback:function ($$v) {_vm.bisTimeMenu=$$v},expression:"bisTimeMenu"}},[(_vm.bisTimeMenu)?_c(VTimePicker,{attrs:{"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.bisMenuRef.save(_vm.bisTime)}},model:{value:(_vm.bisTime),callback:function ($$v) {_vm.bisTime=$$v},expression:"bisTime"}}):_vm._e()],1):_vm._e()],1),_c(VSelect,{staticClass:"type",attrs:{"items":_vm.problemTypes,"rules":[_vm.requiredRule],"required":"","label":"Typ"},model:{value:(_vm.selectedProblemType),callback:function ($$v) {_vm.selectedProblemType=$$v},expression:"selectedProblemType"}})],1),_c('div',{staticClass:"container-fluid"},[_c(VRow,{staticClass:"flex-nowrap"},[_c('div',{staticClass:"col",attrs:{"cols":"12","sm":"8","flex":""}},[_c('span',[_vm._v("Problem")]),_c(VTextarea,{staticClass:"problem-textarea",attrs:{"solo":"","rules":[_vm.requiredRule],"required":""},model:{value:(_vm.problemText),callback:function ($$v) {_vm.problemText=$$v},expression:"problemText"}})],1),_c('div',{staticClass:"col",attrs:{"cols":"12","sm":"8","flex":""}},[_c('span',[_vm._v("Lösung")]),_c(VTextarea,{staticClass:"problem-textarea",attrs:{"solo":""},model:{value:(_vm.solutionText),callback:function ($$v) {_vm.solutionText=$$v},expression:"solutionText"}})],1)])],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VBtn,{staticClass:"anlegen-btn",attrs:{"small":"","fluid":"","color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.editEntry ? 'Speichern' : 'Anlegen')+" "+_vm._s(_vm.formValid))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }