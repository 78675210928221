"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helpers_1 = require("@/services/helpers");
const DEFAULT_URL_KEY_NAME = 'selectedKey';
exports.default = (0, vue_1.defineComponent)({
    props: {
        data: { type: Array, default: null },
        idKey: { type: String, default: null },
        defaultSort: { type: String, default: null },
        height: { type: Number, default: null },
        // eslint-disable-next-line vue/prop-name-casing
        URL_KEY_NAME: { type: String, default: DEFAULT_URL_KEY_NAME },
    },
    data: () => ({
        currentSelected: null,
    }),
    computed: {
        selectedItem() {
            if (this.data && this.$route.params[this.URL_KEY_NAME] && this.idKey) {
                return this.data.find((item) => String(item[this.idKey || '-']) === String(this.$route.params[this.URL_KEY_NAME]));
            }
            return null;
        },
    },
    watch: {
        async selectedItem(selected) {
            if (this.currentSelected === selected) {
                return;
            }
            const tableRef = this.$refs.tableRef;
            await (0, helpers_1.nextTick)();
            tableRef.setCurrentRow(selected);
            // if selection is empty there is no need to scroll element into view
            if (!selected) {
                return;
            }
            await (0, helpers_1.nextTick)();
            // scroll table item into view
            let row = this.$el.querySelector('.el-table__row.current-row');
            let container = this.$el.querySelector('.el-table__body-wrapper');
            if (container && row) {
                container.scrollTop = row.offsetTop;
            }
            else {
                console.warn('did not find native table elements');
            }
        },
    },
    methods: {
        selectionChanged(row) {
            this.currentSelected = row;
            this.$emit('selectionChange', row);
            // check if url needs update
            let urlCurrentKey = this.$route.params[this.URL_KEY_NAME];
            let urlSetKey = row ? row[this.idKey] : undefined;
            if (String(urlCurrentKey) !== String(urlSetKey)) {
                void this.$router.push({
                    name: this.$route.name,
                    params: Object.assign(Object.assign({}, this.$route.params), { [this.URL_KEY_NAME]: urlSetKey }),
                });
            }
        },
    },
});
