"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    props: {
        errors: {
            type: (Array),
            required: true,
            default: () => [],
        },
    },
    computed: {
        groupedErrors() {
            const r = (0, lodash_1.chain)(this.errors).map(e => (Object.assign(Object.assign({}, e), { e: e.errors[0] }))).groupBy(g => g.e).mapValues(ge => (0, lodash_1.groupBy)(ge, x => x.cid)).value();
            console.log('grouped errors', r);
            return r;
        },
    },
});
