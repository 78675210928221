import { VueConstructor } from 'vue-demi';
import { registerRenderVNodeComponent } from './RenderVNode';

import TestComponent from './Test.vue';
import ConfirmButton from './ConfirmButton.vue';

export function registerRoseGlobalComponents(vueInstance: VueConstructor | any) {
  let registerComponents = {
    'rose-test': TestComponent,
    'rose-confirm-button': ConfirmButton,
  };

  Object.entries(registerComponents).forEach(([name, Component]) => {
    vueInstance.component(name, Component as any);
  });

  registerRenderVNodeComponent(vueInstance);
}
