"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    props: ['name', 'status', 'host', 'restartAction', 'cleanCacheAction'],
    data: () => ({}),
    methods: {
        restart() {
            this.restartAction(this.host);
        },
        cleanCache() {
            this.cleanCacheAction(this.host);
        },
    },
});
