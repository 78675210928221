"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const types_1 = require("../../../../types");
const common_ui_1 = require("../../../../common-ui");
exports.default = (0, vue_1.defineComponent)({
    props: {
        licence: { type: Object },
    },
    data: () => ({
        rules: common_ui_1.rules,
    }),
    computed: {
        statusItems() {
            return types_1.EnumValues.getNamesAndValues(types_1.CharlyAnalyticsLicenceStatus);
        },
    },
    methods: {},
});
