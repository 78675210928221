"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userColumns = void 0;
const common_ui_1 = require("@rose/common-ui");
const getName = (params) => `${params.data.firstName} ${params.data.lastName}`;
exports.userColumns = [
    {
        headerName: 'ID / E-Mail',
        field: 'email',
        sort: 'asc',
        sortable: true,
        resizable: true,
        width: 400,
        cellRenderer: 'CopyTextCell',
    },
    { headerName: 'Name', valueGetter: getName, sortable: true, resizable: true, width: 400 },
    {
        headerName: 'Client-IDs',
        field: 'alleclientids',
        cellRenderer: 'ClientIdCell',
        width: 500,
        sortable: true,
        resizable: true,
    },
    { headerName: 'User-Tags', field: 'tags', cellRenderer: 'TagsCell', sortable: true, resizable: true },
    { headerName: 'Client-Tags', field: 'alletags', cellRenderer: 'TagsCell', sortable: true, resizable: true },
    {
        headerName: 'Last Active',
        field: 'lastActivity',
        width: 200,
        valueFormatter: common_ui_1.fromNowFormatter,
        sortable: true,
        resizable: true,
    },
    {
        headerName: 'Views',
        field: 'accessibleViews',
        cellRenderer: 'TagsCell',
        sortable: true,
        resizable: true,
    },
    { headerName: 'Admin Level', field: 'adminLevel', sortable: true, resizable: true },
    { headerName: '2FA Status', field: 'mfatype', sortable: true, resizable: true, valueFormatter: common_ui_1.mfaInfoFormatter },
    {
        headerName: 'Last Password Change',
        field: 'lastPasswordUpdate',
        valueFormatter: common_ui_1.fromNowFormatter,
        sortable: true,
        resizable: true,
    },
    { headerName: 'Changed', field: 'updatedAt', valueFormatter: common_ui_1.dateTimeFormatter, sortable: true, resizable: true },
];
