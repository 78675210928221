"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.todoCol = exports.txtCol = exports.dateCol = exports.euroPercentColPair = exports.percentCol = exports.euroCol = exports.paramMomentFormat = exports.classHoursAlarmCss = exports.classZeroValue = exports.teamIconClass = exports.momentComparer = exports.folgeTermineComparer = exports.behandlerMitIdArrayFormatter = exports.behandlerArrayFormatter = exports.behandlerMitIdFormatter = exports.behandlerFormatter = exports.patientComparer = exports.patientDisplayText = exports.folgeTermineFormatter = exports.folgeTermineRawDateFormatter = exports.booleanJaNeinFormatter = exports.percent100Formatter = exports.percentFormatter = exports.euroAndCentFormatter = exports.euroFormatterEmptyUndefined = exports.euroFormatter = exports.decimalFormatter = exports.lastArrayFormatter = exports.binarySizeFormatter = exports.arrayFormatter = exports.dauerFromMinutesFormatter = exports.dauerFromSecondsFormatter = exports.formatBytes = exports.sexFormatter = exports.hostnameFormatter = exports.versionFormatter = exports.durationFormatter = exports.fromNowWoSuffixFormatter = exports.mfaInfoFormatter = exports.fromNowFormatter = exports.timeFormatter = exports.dateLongYearFormatter = exports.dateTimeFormatter = exports.dateFormatter = exports.dateRawFormatter = exports.getUnitFromGrid = exports.getValueFromGrid = void 0;
const lodash_1 = require("lodash");
const types_1 = require("../../types");
const filters_1 = require("./filters");
const numeral_1 = require("./numeral");
const rose_dayjs_1 = require("./rose-dayjs");
function getValueFromGrid(row, columnDef) {
    // use field if unit was given
    let val = columnDef.field && (0, lodash_1.get)(row, columnDef.field);
    const vf = columnDef.valueFormatter;
    // use formatter otherwise
    if (!getUnitFromGrid(columnDef) || !!columnDef.useFormatterForExport) {
        if (columnDef.excelValueFormatter) {
            val = columnDef.excelValueFormatter({ value: val, data: row });
        }
        else if (vf) {
            val = vf({ value: val, data: row });
        }
    }
    return val !== null && val !== void 0 ? val : '';
}
exports.getValueFromGrid = getValueFromGrid;
function getUnitFromGrid(columnDef) {
    return columnDef.exportUnit || types_1.Units.NONE;
}
exports.getUnitFromGrid = getUnitFromGrid;
function dateRawFormatter(params) {
    return params.value ? rose_dayjs_1.roseDayjs.tz(params.value, 'GMT').toDate() : '';
}
exports.dateRawFormatter = dateRawFormatter;
function dateFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).format('DD.MM.YY') : '';
}
exports.dateFormatter = dateFormatter;
function dateTimeFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).format('DD.MM.YYYY HH:mm') : '';
}
exports.dateTimeFormatter = dateTimeFormatter;
function dateLongYearFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).format('DD.MM.YYYY') : '';
}
exports.dateLongYearFormatter = dateLongYearFormatter;
function timeFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).format('HH:mm') : '';
}
exports.timeFormatter = timeFormatter;
function fromNowFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).fromNow() : '';
}
exports.fromNowFormatter = fromNowFormatter;
function mfaInfoFormatter(params) {
    let { mfatype, mfaenforced } = params.data;
    return `${(0, types_1.mfaTypeToString)(mfatype) || ''} ${mfaenforced ? '🔒' : ''}`;
}
exports.mfaInfoFormatter = mfaInfoFormatter;
function fromNowWoSuffixFormatter(params) {
    return params.value ? (0, rose_dayjs_1.roseDayjs)(params.value).fromNow(true) : '';
}
exports.fromNowWoSuffixFormatter = fromNowWoSuffixFormatter;
function durationFormatter(params) {
    return params.value ? rose_dayjs_1.roseDayjs.duration(params.value, 's').format('H:mm:ss') : '';
}
exports.durationFormatter = durationFormatter;
function versionFormatter(params) {
    const version = (0, rose_dayjs_1.roseDayjs)(params.value, 'DD-MM-YYYY.HH.mm', true);
    if (!version.isValid()) {
        return params === null || params === void 0 ? void 0 : params.value;
    }
    return (params === null || params === void 0 ? void 0 : params.value) && version.format('DD.MM.YYYY HH:mm');
}
exports.versionFormatter = versionFormatter;
function hostnameFormatter(params) {
    if (!(params === null || params === void 0 ? void 0 : params.value)) {
        return '-';
    }
    if (params.value.includes('staging')) {
        return 'staging';
    }
    return (0, lodash_1.chain)(params.value).split('.').first().value() || '-';
}
exports.hostnameFormatter = hostnameFormatter;
function sexFormatter(params) {
    return params.value === types_1.GeschlechtType.MALE ? 'Herr' : params.value === types_1.GeschlechtType.FEMALE ? 'Frau' : '-';
}
exports.sexFormatter = sexFormatter;
// TODO use numeral instead
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
exports.formatBytes = formatBytes;
function dauerFromSecondsFormatter(params) {
    return params.value ? filters_1.dauerFilter.filters.dauer(params.value) : '-';
}
exports.dauerFromSecondsFormatter = dauerFromSecondsFormatter;
function dauerFromMinutesFormatter(params) {
    return params.value ? filters_1.dauerFilter.filters.dauer(params.value, true) : '-';
}
exports.dauerFromMinutesFormatter = dauerFromMinutesFormatter;
function arrayFormatter(params) {
    return params.value ? params.value.join(', ') : '-';
}
exports.arrayFormatter = arrayFormatter;
function binarySizeFormatter(params) {
    if (!(params === null || params === void 0 ? void 0 : params.value) || (params === null || params === void 0 ? void 0 : params.value) < 0) {
        return '-';
    }
    return params.value ? formatBytes(params.value, 1) : '-';
}
exports.binarySizeFormatter = binarySizeFormatter;
function lastArrayFormatter(params) {
    if (!(params === null || params === void 0 ? void 0 : params.value) || (params === null || params === void 0 ? void 0 : params.value.length) === 0) {
        return '-';
    }
    return (params === null || params === void 0 ? void 0 : params.value) && (0, rose_dayjs_1.roseDayjs)(params.value.pop().lastActivity).format('DD.MM.YYYY HH:mm');
}
exports.lastArrayFormatter = lastArrayFormatter;
function decimalFormatter(params) {
    if (params.value || params.value === 0) {
        return (0, numeral_1.numeral)(params.value, { fractionDigits: 0 });
    }
    if (params.value === undefined) {
        return '-';
    }
    return (0, numeral_1.numeral)(0, { fractionDigits: 0 });
}
exports.decimalFormatter = decimalFormatter;
function euroFormatter(params) {
    if (params.value || params.value === 0) {
        return (0, numeral_1.numeral)(params.value, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
    }
    if (params.value === undefined) {
        return '-';
    }
    return (0, numeral_1.numeral)(0, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
}
exports.euroFormatter = euroFormatter;
function euroFormatterEmptyUndefined(params) {
    if (params.value || params.value === 0) {
        return (0, numeral_1.numeral)(params.value, { fractionDigits: 0, style: 'currency', currency: 'EUR' });
    }
    return '';
}
exports.euroFormatterEmptyUndefined = euroFormatterEmptyUndefined;
function euroAndCentFormatter(params) {
    if (params.value || params.value === 0) {
        return (0, numeral_1.numeral)(params.value, { fractionDigits: 2, style: 'currency', currency: 'EUR' });
    }
    return '';
}
exports.euroAndCentFormatter = euroAndCentFormatter;
function percentFormatter(params) {
    if (params.value || params.value === 0) {
        return `${(0, numeral_1.numeral)(params.value, { style: 'decimal', fractionDigits: 0 })} %`;
    }
    return '';
}
exports.percentFormatter = percentFormatter;
function percent100Formatter(params) {
    if (params.value || params.value === 0) {
        return `${(0, numeral_1.numeral)(params.value * 100, { style: 'decimal', fractionDigits: 0 })} %`;
    }
    return '';
}
exports.percent100Formatter = percent100Formatter;
function booleanJaNeinFormatter(params) {
    if (!params) {
        return '';
    }
    if (params.value) {
        return 'ja';
    }
    if (!params.value) {
        return 'nein';
    }
    return '';
}
exports.booleanJaNeinFormatter = booleanJaNeinFormatter;
function folgeTermineRawDateFormatter(params) {
    return folgeTermineBaseFormatter(params, true);
}
exports.folgeTermineRawDateFormatter = folgeTermineRawDateFormatter;
function folgeTermineFormatter(params) {
    return folgeTermineBaseFormatter(params, false);
}
exports.folgeTermineFormatter = folgeTermineFormatter;
function folgeTermineBaseFormatter(params, asDate, tz = 'GMT') {
    let folgeTermine = params.value;
    if (folgeTermine) {
        let nextTermin = (0, lodash_1.chain)(folgeTermine)
            .filter(t => t.status !== types_1.TerminStatusType.OFFEN && (0, rose_dayjs_1.roseDayjs)().isBefore(t.tag))
            .sortBy(t => t.tag)
            .first()
            .value();
        if (nextTermin) {
            return asDate ? rose_dayjs_1.roseDayjs.tz(nextTermin.tag, tz).toDate() : (0, rose_dayjs_1.roseDayjs)(nextTermin.tag).format('YYYY-MM-DD');
        }
    }
    let hatOffenenTermin = (0, lodash_1.some)(folgeTermine, t => t.status === types_1.TerminStatusType.OFFEN);
    return hatOffenenTermin ? 'OT' : '';
}
function patientDisplayText(p) {
    if (!p) {
        return '-';
    }
    if (!p.name && !p.vorname) {
        return `Name konnte nicht aufgelöst werden (${p.patid})`;
    }
    return `${p.name}, ${p.vorname} (${p.patid})`;
}
exports.patientDisplayText = patientDisplayText;
function patientComparer(x, y, a, b) {
    return patientDisplayText(a.data).localeCompare(patientDisplayText(b.data));
}
exports.patientComparer = patientComparer;
function behandlerFormatter(behandlerService) {
    return (params) => params && params.value && behandlerService.getProperty(params.value);
}
exports.behandlerFormatter = behandlerFormatter;
function behandlerMitIdFormatter(behandlerService) {
    return (params) => {
        if (params && params.value) {
            const id = params.value;
            return `${behandlerService.getProperty(id)} (${id})`;
        }
    };
}
exports.behandlerMitIdFormatter = behandlerMitIdFormatter;
function behandlerArrayFormatter(behandlerService, getBehandlerId = (v) => v) {
    return (params) => (0, lodash_1.chain)(params === null || params === void 0 ? void 0 : params.value)
        .map(v => behandlerService.getProperty(getBehandlerId(v)))
        .join(', ')
        .value();
}
exports.behandlerArrayFormatter = behandlerArrayFormatter;
function behandlerMitIdArrayFormatter(behandlerService, getBehandlerId = (v) => v) {
    return (params) => (0, lodash_1.chain)(params === null || params === void 0 ? void 0 : params.value)
        .map(v => {
        const id = getBehandlerId(v);
        return `${behandlerService.getProperty(id)} (${id})`;
    })
        .join(', ')
        .value();
}
exports.behandlerMitIdArrayFormatter = behandlerMitIdArrayFormatter;
function folgeTermineComparer(x, y, a, b) {
    var _a, _b;
    let af = (_a = a.data) === null || _a === void 0 ? void 0 : _a.folgetermine;
    let bf = (_b = b.data) === null || _b === void 0 ? void 0 : _b.folgetermine;
    if (!af || !bf) {
        return 0;
    }
    let afs = (0, lodash_1.size)(af.filter(t => t.status !== types_1.TerminStatusType.OFFEN));
    let bfs = (0, lodash_1.size)(bf.filter(t => t.status !== types_1.TerminStatusType.OFFEN));
    let aos = (0, lodash_1.size)(af.filter(t => t.status === types_1.TerminStatusType.OFFEN));
    let bos = (0, lodash_1.size)(bf.filter(t => t.status === types_1.TerminStatusType.OFFEN));
    return bfs * 100 + bos - (afs * 100 + aos);
}
exports.folgeTermineComparer = folgeTermineComparer;
function momentComparer() {
    return (a, b) => {
        if (a && b) {
            return a.valueOf() - b.valueOf();
        }
        return a ? 1 : b ? -1 : 0;
    };
}
exports.momentComparer = momentComparer;
function teamIconClass(team) {
    switch (team.typ) {
        case types_1.TeamTypes.MITARBEITER:
            return 'user';
        case types_1.TeamTypes.SINGLE:
            return team.singleTyp === types_1.BehandlerType.PZR ? 'user-nurse' : 'user-md';
        case types_1.TeamTypes.BUNDLE:
            return 'user-md';
        case types_1.TeamTypes.PRAXIS:
            return 'hospital';
        case types_1.TeamTypes.INAKTIV:
            return 'user-times';
        case types_1.TeamTypes.TERMINGRUPPE:
            return 'calendar-alt';
        case types_1.TeamTypes.TEAM:
        case types_1.TeamTypes.AUTOTEAM:
        case types_1.TeamTypes.ALLE:
        case types_1.TeamTypes.UNUSED:
            return 'users';
        default:
            return 'question-circle';
    }
}
exports.teamIconClass = teamIconClass;
function classZeroValue(params) {
    if ((params && params.value < 0.005 && params.value > -0.005) || !params.value) {
        return 'cell-zero-value';
    }
}
exports.classZeroValue = classZeroValue;
function classHoursAlarmCss(cell, hourThreshold, className, lessThen) {
    if (hourThreshold && cell) {
        const cellMoment = (0, rose_dayjs_1.roseDayjs)(cell);
        const hoursDiff = rose_dayjs_1.roseDayjs.duration(rose_dayjs_1.roseDayjs.utc().diff(cellMoment)).asHours();
        if (lessThen) {
            if (hoursDiff < hourThreshold && className) {
                return className;
            }
        }
        else if (hoursDiff > hourThreshold && className) {
            return className;
        }
    }
    return 'none';
}
exports.classHoursAlarmCss = classHoursAlarmCss;
exports.paramMomentFormat = 'YYYY-MM-DD';
const baseCol = (field, headerName, columnGroup, width, columnGroupShow) => ({
    field,
    width,
    headerName,
    headerTooltip: headerName,
    columnGroupShow: columnGroupShow === true ? 'closed' : columnGroupShow === false ? 'open' : 'always',
    columnGroup,
    sortable: true,
    resizable: true,
});
const euroCol = (field, headerName, columnGroup = 'default', width = 80, columnGroupShow) => (Object.assign(Object.assign({}, baseCol(field, headerName, columnGroup, width, columnGroupShow)), { exportUnit: types_1.Units.EURO, valueFormatter: euroFormatter, cellStyle: { 'text-align': 'right' }, cellClass: (params) => classZeroValue(params) }));
exports.euroCol = euroCol;
const percentCol = (field, headerName, columnGroup = 'default', width = 80, columnGroupShow, useBy100Formatter = false) => (Object.assign(Object.assign({}, baseCol(field, headerName, columnGroup, width, columnGroupShow)), { exportUnit: types_1.Units.PROZENTE, valueFormatter: useBy100Formatter ? percent100Formatter : percentFormatter, cellStyle: { 'text-align': 'right' }, cellClass: (params) => classZeroValue(params) }));
exports.percentCol = percentCol;
const euroPercentColPair = (field, headerName, columnGroup = 'default', width = 80, columnGroupShow, useBy100Formatter = false) => ({
    headerName: headerName,
    headerTooltip: headerName,
    columnGroupShow: 'open',
    children: [
        (0, exports.euroCol)(field, `€`, columnGroup, width, columnGroupShow),
        (0, exports.percentCol)(`${field}anteil`, `%`, columnGroup, width, columnGroupShow, useBy100Formatter),
    ],
});
exports.euroPercentColPair = euroPercentColPair;
const dateCol = (field, headerName, columnGroup = 'default', width = 80, columnGroupShow) => (Object.assign(Object.assign({}, baseCol(field, headerName, columnGroup, width, columnGroupShow)), { exportUnit: types_1.Units.DATUM, excelValueFormatter: dateRawFormatter, useFormatterForExport: true }));
exports.dateCol = dateCol;
const txtCol = (field, headerName, columnGroup = 'default', width = 80, columnGroupShow) => baseCol(field, headerName, columnGroup, width, columnGroupShow);
exports.txtCol = txtCol;
const todoCol = (headerName, columnGroup = 'default', width = 180, columnGroupShow) => (0, exports.txtCol)('todo', headerName, columnGroup, width, columnGroupShow);
exports.todoCol = todoCol;
