"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({ importPaused: undefined }),
    async mounted() {
        await this.fetchImportStatus();
    },
    methods: {
        async runFullImport() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.runFullImportSchedule)(),
                successMessage: 'Der vollständige Import wird ausgeführt',
            });
        },
        // import status
        async fetchImportStatus() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.getImportStatus)(),
                successMessage: null,
                errorMessage: 'Import Status konnte nicht geladen werden',
                successCallback: response => {
                    var _a, _b;
                    this.importPaused = ((_a = response.data) === null || _a === void 0 ? void 0 : _a.full) || ((_b = response.data) === null || _b === void 0 ? void 0 : _b.incremental);
                },
            });
        },
        async pauseImport() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.pauseImport)(),
                successMessage: 'Import pausiert',
            });
            await this.fetchImportStatus();
        },
        async resumeImport() {
            await (0, helpers_1.handleApiCall)({
                apiFunction: () => (0, data_1.resumeImport)(),
                successMessage: 'Import fortgesetzt',
            });
            await this.fetchImportStatus();
        },
    },
});
