import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","max-height":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"small":"","label":"","color":_vm.filterGroup.bg,"outlined":!_vm.selectedItem,"dark":_vm.filterGroup.dark}},'v-chip',attrs,false),on),[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(_vm._s(_vm.filterGroup.name))]),(_vm.selectedItem)?_c('span',[_vm._v(_vm._s(_vm.selectedItem.name)+" "+_vm._s(_vm.selectedItem.d.count))]):_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-filters")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,attrs:{"value":item}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))]),_c(VListItemSubtitle,{staticClass:"d-flex caption align-center justify-space-between"},[_c('span',[_vm._v(_vm._s(item.d.count))]),_c('span',[_vm._v(_vm._s(item.d.percentage)+"%")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }