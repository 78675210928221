"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const common_ui_1 = require("@rose/common-ui");
const globalSearch_state_1 = require("@/state/globalSearch.state");
exports.default = (0, vue_1.defineComponent)({
    name: 'ClientImportCell',
    data: () => ({
        params: null,
        importInfos: null,
        incError: false,
        fullError: false,
    }),
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        this.setValues();
    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh(params) {
            // set value into cell again
            //   this.cellValue = this.getValueToDisplay(params);
            this.setValues();
            return true;
        },
        goToImport(event) {
            var _a, _b, _c, _d;
            if (event.target.id === 'i') {
                globalSearch_state_1.globalSearchStore.setSearchInput(((_b = (_a = this.importInfos) === null || _a === void 0 ? void 0 : _a.incremental) === null || _b === void 0 ? void 0 : _b.importid) || '');
                this.$router.push('/import');
            }
            if (event.target.id === 'f') {
                globalSearch_state_1.globalSearchStore.setSearchInput(((_d = (_c = this.importInfos) === null || _c === void 0 ? void 0 : _c.full) === null || _d === void 0 ? void 0 : _d.importid) || '');
                this.$router.push('/import');
            }
        },
        setValues() {
            var _a, _b, _c;
            if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.value) {
                this.importInfos = this.params.value;
                if ((_b = this.importInfos) === null || _b === void 0 ? void 0 : _b.incremental) {
                    const incStartTime = (0, common_ui_1.roseDayjs)(this.importInfos.incremental.started);
                    const hoursDiff = common_ui_1.roseDayjs.duration(common_ui_1.roseDayjs.utc().diff(incStartTime)).asHours();
                    this.incError =
                        (this.importInfos.incremental.duration || 0) > 10 * 60 ||
                            hoursDiff > 1 ||
                            this.importInfos.incremental.errorcount > 0;
                }
                if ((_c = this.importInfos) === null || _c === void 0 ? void 0 : _c.full) {
                    const fullStartTime = (0, common_ui_1.roseDayjs)(this.importInfos.full.started);
                    const hoursDiff = common_ui_1.roseDayjs.duration(common_ui_1.roseDayjs.utc().diff(fullStartTime)).asHours();
                    this.fullError =
                        (this.importInfos.full.duration || 0) > 4 * 60 * 60 ||
                            hoursDiff > 25 ||
                            this.importInfos.full.errorcount > 0;
                }
            }
        },
    },
});
