"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        params: {},
        value: '',
    }),
    beforeMount() {
        this.value = this.params.value;
    },
    computed: {
        canSave() {
            return this.value !== this.params.value;
        },
    },
    methods: {
        onChange(event) {
            const target = event.target;
            this.value = target.value;
        },
        onCommit(event) {
            event.preventDefault();
            event.stopPropagation();
            this.params.context.clicked(this.params, this.value);
        },
        refresh(params) {
            this.params = params;
            this.value = params.value;
        },
    },
});
