"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionInvoiceColumns = void 0;
const common_ui_1 = require("@rose/common-ui");
function subscriptionInvoiceColumns(smallScreen) {
    return [
        {
            headerName: 'Customer-ID',
            field: 'customer',
            sort: 'asc',
            cellRenderer: 'ClientsClientIdCell',
            headerTooltip: 'Eindeutige ID des Kunden',
            width: 170,
            pinned: smallScreen ? undefined : 'left',
        },
        {
            headerName: 'Invoice-ID',
            field: 'id',
            headerTooltip: 'Eindeutige ID der Rechnung',
            width: 240,
        },
        {
            headerName: 'Features',
            field: 'features',
            headerTooltip: 'Features des Abonnements',
            width: 400,
            valueFormatter: common_ui_1.arrayFormatter,
        },
        {
            headerName: 'Rechnungsdatum',
            field: 'created',
            headerTooltip: 'Datum der Rechnung',
            valueFormatter: common_ui_1.dateFormatter,
            width: 140,
        },
        {
            headerName: 'Rechnungsnummer',
            field: 'number',
            headerTooltip: 'Nummer der Rechnung',
            cellRenderer: 'ClientsClientIdCell',
            width: 140,
        },
        {
            headerName: 'Betrag',
            field: 'amount',
            headerTooltip: 'Betrag der Rechnung',
            valueFormatter: common_ui_1.euroAndCentFormatter,
            width: 140,
            flex: 1,
        },
    ];
}
exports.subscriptionInvoiceColumns = subscriptionInvoiceColumns;
