"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalSearchStore = void 0;
const vue_1 = require("vue");
exports.globalSearchStore = (0, vue_1.reactive)({
    searchInput: '',
    setSearchInput(searchInput) {
        this.searchInput = searchInput;
    },
});
