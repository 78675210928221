"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const helpers_1 = require("@/services/helpers");
const vue_1 = require("vue");
const stripe_1 = require("@/services/stripe");
const common_ui_1 = require("../../../../common-ui");
exports.default = (0, vue_1.defineComponent)({
    props: {
        stripeCustomer: {
            type: Object,
            default: null,
        },
        heading: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        // change iban dialog
        changeIbanDialog: false,
        ibanValid: false,
        ibanElement: null,
        isStaging: common_ui_1.isStaging,
        isDev: common_ui_1.isDev,
        loading: false,
    }),
    methods: {
        async changeIban() {
            try {
                this.loading = true;
                let stripe = await (0, stripe_1.getStripe)();
                let { client_secret } = await data_1.stripeApi.createPaymentIntent();
                console.log('paymentIntentId', client_secret);
                console.log('stripe customer', this.stripeCustomer);
                if (!this.stripeCustomer) {
                    alert('fail');
                    return;
                }
                let res = await stripe.confirmSepaDebitSetup(client_secret, {
                    payment_method: {
                        sepa_debit: this.ibanElement,
                        billing_details: {
                            name: this.stripeCustomer.name,
                            email: this.stripeCustomer.email,
                        },
                    },
                });
                if (res.error) {
                    throw res.error;
                }
                if (!res.setupIntent) {
                    throw new Error('stripe: no setupIntent');
                }
                const payment_method = res.setupIntent.payment_method;
                if (!payment_method) {
                    throw new Error('stripe: no payment method');
                }
                await data_1.stripeApi.changeClientIban(this.stripeCustomer.id, payment_method);
                (0, helpers_1.notifySuccess)('Geändert');
                this.changeIbanDialog = false;
            }
            catch (e) {
                console.log('error', e);
                (0, helpers_1.notifyError)('Fehler! ' + e.message);
            }
            finally {
                this.loading = false;
            }
        },
        async startChangeIban() {
            this.changeIbanDialog = true;
            let style = {
                base: {
                    fontSize: '16px',
                    color: '#32325d',
                },
            };
            let options = {
                style: style,
                supportedCountries: ['SEPA'],
                // If you know the country of the customer, you can optionally pass it to
                // the Element as placeholderCountry. The example IBAN that is being used
                // as placeholder reflects the IBAN format of that country.
                placeholderCountry: 'DE',
            };
            let stripeElements = await (0, stripe_1.getStripeElements)();
            let ibanElement = stripeElements.create('iban', options);
            ibanElement.mount('#iban-element');
            ibanElement.on('change', (event) => {
                this.ibanValid = event.complete;
            });
            this.ibanElement = ibanElement;
        },
    },
});
