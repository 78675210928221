"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const lodash_1 = require("lodash");
const L = __importStar(require("leaflet"));
const vue2_leaflet_1 = require("vue2-leaflet");
const data_1 = require("@/services/data");
exports.default = (0, vue_1.defineComponent)({
    components: {
        LMap: vue2_leaflet_1.LMap,
        LTileLayer: vue2_leaflet_1.LTileLayer,
        LMarker: vue2_leaflet_1.LMarker,
        LIcon: vue2_leaflet_1.LIcon,
        LPopup: vue2_leaflet_1.LPopup,
    },
    data: () => ({
        zoom: 9,
        mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        mapAttribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        currentZoom: 11.5,
        currentCenter: L.latLng(50.7822424, 6.059347481118625),
        showParagraph: false,
        mapOptions: {
            zoomSnap: 0.5,
        },
        mapCenter: null,
        loading: false,
        locations: [],
        icon: L.icon({
            iconUrl: '/rose_o_w_bg.png',
            iconSize: [35, 35],
            iconAnchor: [17, 37],
            popupAnchor: [0, -35],
        }),
    }),
    computed: {
        center() {
            return L.latLng(50.7822424, 6.059347481118625);
            if ((0, lodash_1.isEmpty)(this.locations)) {
                return L.latLng(50.7822424, 6.059347481118625);
            }
            return L.latLng(this.mapCenter);
        },
        clientCount() {
            return (0, lodash_1.size)(this.locations);
        },
    },
    methods: {
        async reloadAction() {
            this.loading = true;
            try {
                const cl = await (0, data_1.clientLocations)();
                this.locations = (0, lodash_1.map)(cl, x => (Object.assign({ cid: x.cid, pn: x.praxisKurzName }, x.location)));
                console.log('locations', this.locations);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.loading = false;
            }
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
    },
    async mounted() {
        await this.reloadAction();
    },
});
