"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setImportCount = exports.visibleSubscription = exports.startTrackingVisibility = exports.visible$ = void 0;
const Visibility = __importStar(require("visibilityjs"));
const rxjs_1 = require("rxjs");
const types_1 = require("../../../types");
const data_1 = require("@/services/data");
const _ = __importStar(require("lodash"));
const import_state_1 = require("@/state/import.state");
let updateTimeout;
exports.visible$ = new rxjs_1.BehaviorSubject(Visibility ? Visibility.isSupported() : true);
function startTrackingVisibility() {
    Visibility.change((ev, state) => {
        exports.visible$.next(state === 'visible');
    });
}
exports.startTrackingVisibility = startTrackingVisibility;
function visibleSubscription() {
    exports.visible$.subscribe(async (visible) => {
        // update if last update was longer than 5 seconds ago
        clearTimeout(updateTimeout);
        // disable autoload
        // updateTimeout = setTimeout(async () => {
        //   if (visible) {
        //     await setImportCount();
        //     visibleSubscription();
        //   }
        // }, 1000 * 10);
    });
}
exports.visibleSubscription = visibleSubscription;
async function setImportCount() {
    let count = 0;
    const aipRaw = await (0, data_1.loadAllImportProgress)();
    let allImportProgress = aipRaw.imports;
    allImportProgress = _.filter(allImportProgress, i => (i.incrementalInfo && i.incrementalInfo.step) !== types_1.IMPORT_STEPS.FINISHED);
    for (let importItem of allImportProgress) {
        if ((importItem.incrementalInfo && importItem.incrementalInfo.iinfo) ||
            (importItem.fullInfo && importItem.fullInfo.iinfo)) {
            count++;
        }
    }
    import_state_1.importStateStore.setImportCount(count);
}
exports.setImportCount = setImportCount;
